import ExportAndFilter from "components/common/ExportAndFilter/ExportAndFilter";
import { useEffect, useState } from "react";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import FilterWrapper from "utils/FilterWrapper/FilterWrapper";
import { walletsTableData } from "components/Dashboard/Watcher/utils/data";
import { providerFiltersDefaultValues } from "../helpers/data";

const ProviderFilters = ({ data, filters, handleFilters, clearFilters }) => {
	const [isApplied, setIsApplied] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [formValues, setFormValues] = useState({
		...providerFiltersDefaultValues,
	});
	const disabled = formValues?.reference || formValues?.text;

	useEffect(() => {
		if (filters) {
			setFormValues(filters);
			setIsApplied(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = ({ target: { name, value } }) =>
		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));

	const clearFilter = () => {
		setFormValues({ ...providerFiltersDefaultValues });
		clearFilters();
		setIsApplied(false);
	};

	const handleSubmit = () => {
		if (disabled) {
			handleFilters({ data: formValues });
			setIsApplied(true);
		}
	};

	return (
		<>
			<ExportAndFilter
				data={{
					...data,
					filters,
					tableHeadings: walletsTableData,
					subTitle: "Download Watcher Transactions Report",
				}}
				handleFilters={handleFilters}
				clearFilters={clearFilters}
				hideExportBtn={!data}
				onClick={() => setIsOpen((prev) => !prev)}
			/>
			{isOpen && (
				<FilterWrapper
					handleFilter={handleSubmit}
					clearFilter={clearFilter}
					isApplied={isApplied}
					disabled={!disabled}
				>
					<div>
						<CustomInputBox
							name="reference"
							label="Reference"
							value={formValues?.reference}
							onChange={handleChange}
						/>
					</div>
				</FilterWrapper>
			)}
			{/* <ExportAndFilter onClick={() => setIsOpen((prev) => !prev)} /> */}
		</>
	);
};

export default ProviderFilters;
