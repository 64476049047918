import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import ScrollToTop from "utils/ScrollToTop/ScrollToTop";
import Sidebar from "./Layout/Sidebar/Sidebar";
import SubRoutes from "components/RoutesWrapper/subRoutes";
import Header from "./Layout/Header/Header";
import Routes from "components/RoutesWrapper/routes";
import "./Dashboard.scss";

const Dashboard = ({ history, location }) => {
	const getRoutes = () => (
		<Switch>
			<SubRoutes routes={Routes} />
			<Route exact path="*" render={() => <Redirect to="/" />} />
		</Switch>
	);

	return (
		<div className="position-relative">
			<section className="container">
				<Sidebar location={location} history={history} />

				<div className="main active">
					<Header />
					<ScrollToTop />
					<div
						className={
							location.pathname === "/watcher/registrations"
								? "content pt-4"
								: "content bg-main pt-4"
						}
					>
						{getRoutes()}
					</div>
				</div>
			</section>
		</div>
	);
};

export default withRouter(Dashboard);
