import ChartStatus from "components/common/ChartStatus/ChartStatus";
import { totalTransactionsChartOptions } from "../../DashboardPayment/utils/data";
import ChartGraph from "utils/Charts/ChartGraph/ChartGraph";

const data = [
	{
		color: "#62CAA4",
		value: 200,
		name: "Live Dashboard",
		cls: "recent-success-icon ",
	},
	{
		color: "#F1F7FE",
		value: 250,
		name: "Test Dashboard",
		cls: "recent-light-blue-icon ",
	},
];

const DashStatistics = () => {
	return (
		<div className="bg-white pt-4 my-5">
			<p className="title px-5">Dashboard Statistics</p>
			<div className="flex__center">
				<ChartGraph
					option={totalTransactionsChartOptions(data)}
					styles={{
						height: "294px",
					}}
				/>
			</div>
			<div className="px-2 pb-3">
				{data.map(({ value, name, cls }) => (
					<ChartStatus value={value} name={name} cls={cls} />
				))}
			</div>
		</div>
	);
};

export default DashStatistics;
