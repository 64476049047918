import { useHistory, useParams } from "react-router-dom";
import DetailsHeader from "components/common/Details/DetailsHeader/DetailsHeader";
import SectionWrapper from "components/common/section/SectionWrapper/SectionWrapper";
import { ReactComponent as LinkIcon } from "assets/images/icons/link.svg";
import DetailSection from "./DetailSection";
import { cardResponse } from "../../helpers/data";
import CardTable from "../../BankingWallets/WalletCard/CardTable";

function CardDetails() {
	const history = useHistory();
	const { id } = useParams();
	return (
		<SectionWrapper
			cls="pb-0"
			containerCls="d-flex flex-wrap"
			routeName={
				<DetailsHeader
					detailID={id}
					icon={<LinkIcon className="me-3" />}
					IDName="Transaction Id"
					returnAction={() => history.push("/banking/wallets")}
					tabName="Banking Wallets"
				/>
			}
			navTabChildren={
				<div className="d-flex">
					<button className="btn btn--sm btn--secondary--bordered me-4"></button>
					<button className="btn btn--sm btn--secondary--bordered me-4"></button>
				</div>
			}
		>
			<div className="col-12 col-lg-12 ps-lg-5 pt-5 pb-lg-5 pe-lg-5 overflow-md-x d-flex d-lg-block">
				<div className="border-bottom">
					<DetailSection res={cardResponse} />
				</div>
				<CardTable />
			</div>
		</SectionWrapper>
	);
}

export default CardDetails;
