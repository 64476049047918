import ChartStatus from "components/common/ChartStatus/ChartStatus";
import ChartGraph from "utils/Charts/ChartGraph/ChartGraph";
import { ReactComponent as Visa } from "assets/images/icons/visacard.svg";
import { ReactComponent as MasterCard } from "assets/images/icons/mastercard.svg";
import { ReactComponent as UnionPay } from "assets/images/icons/unionPay.svg";
import { ReactComponent as AmericanExpress } from "assets/images/icons/americanExpress.svg";
import { totalTransactionsChartOptions } from "../../DashboardPayment/utils/data";

const data = [
	{
		color: "#2CC7A2",
		value: 200,
		name: "Visa Card",
		cls: "recent-success-icon ",
		icon: <Visa />,
	},
	{
		color: "#F18A87",
		value: 250,
		name: "MasterCard",
		cls: "recent-danger-icon ",
		icon: <MasterCard />,
	},
	{
		color: "#4ACFF9",
		value: 200,
		name: "American Express",
		cls: "recent-blue-icon ",
		icon: <UnionPay />,
	},
	{
		color: "#F8FBFE",
		value: 250,
		name: "Union Pay",
		cls: "recent-light-blue-icon ",
		icon: <AmericanExpress />,
	},
];

const CardStatistics = () => {
	return (
		<>
			<div className="flex__center">
				<ChartGraph
					option={totalTransactionsChartOptions(data)}
					styles={{
						height: "255px",
					}}
				/>
			</div>
			<div className="px-2 pb-3">
				{data?.map(({ value, name, cls, icon }) => (
					<ChartStatus
						value={value}
						name={name}
						cls={cls}
						icon={icon}
					/>
				))}
			</div>
		</>
	);
};

export default CardStatistics;
