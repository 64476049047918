import { useState } from "react";
import DateSelect from "utils/select/DateSelect/DateSelect";
import RevenueStatistics from "./RevenueStatistics/RevenueStatistics";
import PaymentDashboardAnalyticsCard from "./utils/Cards/PaymentDashboardAnalyticsCard";
import {
	infoStatsDateOptions,
	paymentDashboardAnalyticsData,
	transactionDistributionData,
	transactionDistributionPercent,
} from "./utils/data";

import TransactionStatus from "./TransactionStatus/TransactionStatus";
import TransactionStatusOther from "./TransactionStatus/TransctionStatusOthers";
import DisputeStatistics from "./Statistics/DisputeStatistics";
import TransactionDistribution from "./utils/Cards/TransactionDistribution";
import ProgressBar from "utils/ProgressBar/ProgressBar";
import HighRiskTransactions from "./Statistics/HighRiskTransactions";

import "./utils/common.scss";

const PaymentTransactions = () => {
	const [dateRange, setDateRange] = useState(
		"This Week (12 Mar, 2022 - 18 Mar, 2022)"
	);
	return (
		<div>
			<div className="pl-4 d-flex  mb-3">
				<DateSelect
					cls="col-9 col-sm-10"
					wrapperCls="col-9 col-sm-10 col-md-4 col-lg-3 bg-white"
					name="date-range"
					formLabel="date-range"
					onChange={(value) => setDateRange(value)}
					options={infoStatsDateOptions}
					value={dateRange}
				/>
			</div>
			<div className="row">
				{paymentDashboardAnalyticsData.map((data, i) => (
					<PaymentDashboardAnalyticsCard data={data} key={i} />
				))}
			</div>
			<div className="row ">
				<div className="col-xl-6 col-md-12">
					<RevenueStatistics />
					<div className="row h-412">
						<div className="col-xl-6 col-md-12 h-100">
							<TransactionStatus />
						</div>
						<div className="col-xl-6 col-md-12 h-100">
							<TransactionStatusOther />
						</div>
					</div>
					<div className="row h-412 my-4">
						<div className="col-xl-6 col-md-12 h-100">
							<DisputeStatistics />
						</div>
						<div className="col-xl-6 col-md-12 h-100">
							<TransactionStatusOther />
						</div>
					</div>
				</div>
				<div className="col-xl-6 col-md-12">
					<div className="bg-white p-4">
						<div className="flex_between">
							<p className="title">Transaction Distribution</p>
						</div>
						<div className="row mt-5">
							{transactionDistributionData.map((data, i) => (
								<TransactionDistribution data={data} key={i} />
							))}
						</div>
						{transactionDistributionPercent.map((data, i) => (
							<div className="progress-bar my-2">
								<ProgressBar
									bgcolor="#509FEF"
									completed={10 * (i + 1)}
									text={data?.name}
								/>
							</div>
						))}
					</div>
					<div className="bg-white p-4 my-4">
						<p className="title">High Risks Transactions</p>
						<HighRiskTransactions />
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentTransactions;
