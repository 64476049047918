import React from "react";
import { useState } from "react";

function SideTabs({ tabComponent }) {
	const [showComp, setShowComp] = useState(tabComponent[0].id);
	return (
		<div className="row w-100 ">
			<div className="col-lg-2 col-12" id="border-right-bottom--gray">
				<div className="info__section py-5">
					<div className="list-section-wrapper">
						{tabComponent.map((tab) => (
							<p
								className={
									tab.id === showComp
										? "active pointer"
										: "pointer"
								}
								onClick={() => setShowComp(tab.id)}
							>
								{tab.name}
							</p>
						))}
					</div>
				</div>
			</div>
			<div className="col-12 col-lg-10 ps-lg-5 pt-5 pb-lg-5 pe-lg-5 overflow-md-x d-flex d-lg-block">
				{tabComponent.map((tab) => tab.id === showComp && tab.Comp)}
			</div>
		</div>
	);
}

export default SideTabs;
