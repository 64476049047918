import { useState } from "react";
import DateSelect from "utils/select/DateSelect/DateSelect";
import {
	aggregatorDashboardAnalyticsData,
	infoStatsDateOptions,
} from "../DashboardPayment/utils/data";
import PaymentDashboardAnalyticsCard from "../DashboardPayment/utils/Cards/PaymentDashboardAnalyticsCard";
import AggregatorStatistics from "./components/AggregatorStatistics";
import NewRegistrations from "./components/NewRegistrations";
import MostActiveDays from "./components/MostActiveDays";
import FinancialReports from "./components/FinancialReport";
import TopMerchants from "./components/TopMerchants";
import { topMerchantsData } from "./components/data";

const DashboardAggregators = () => {
	const [dateRange, setDateRange] = useState(
		"This Week (12 Mar, 2022 - 18 Mar, 2022)"
	);
	return (
		<div>
			<div className="pl-4 d-flex  mb-3">
				<DateSelect
					cls="col-9 col-sm-10"
					wrapperCls="col-9 col-sm-10 col-md-4 col-lg-3 bg-white"
					name="date-range"
					formLabel="date-range"
					onChange={(value) => setDateRange(value)}
					options={infoStatsDateOptions}
					value={dateRange}
				/>
			</div>
			<div className="row">
				{aggregatorDashboardAnalyticsData.map((data, i) => (
					<PaymentDashboardAnalyticsCard data={data} key={i} />
				))}
			</div>
			<div className="row">
				<div className="col-xl-3 col-md-12">
					<div className="bg-white p-4">
						<div className="title">Aggregator Statistics</div>
						<AggregatorStatistics />
					</div>
				</div>
				<div className="col-xl-6 col-md-12">
					<div className="bg-white p-4">
						<div className="title">New Registrations</div>
						<NewRegistrations />
					</div>
				</div>
				<div className="col-xl-3 col-md-12">
					<div className="bg-white p-4">
						<div className="title">Most Active Days</div>
						<MostActiveDays />
					</div>
				</div>
			</div>
			<div className="row mt-4">
				<div className="col-xl-3 col-md-12">
					<div className="bg-white p-4">
						<div className="title mb-5">
							Top Merchants based on customers
						</div>
						{topMerchantsData.map((data) => (
							<TopMerchants
								progress={data?.progress}
								title={data?.title}
								amount={data.amount}
							/>
						))}
					</div>
				</div>

				<div className="col-xl-3 col-md-12">
					<div className="bg-white p-4">
						<div className="title mb-5">
							Top Merchants based on Revenue
						</div>
						{topMerchantsData.map((data) => (
							<TopMerchants
								progress={data?.progress}
								title={data?.title}
								amount={data.amount}
							/>
						))}
					</div>
				</div>
				<div className="col-xl-6 col-md-12">
					<div className="bg-white px-4 pt-4">
						<div className="title">Financial Report</div>
						<FinancialReports />
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashboardAggregators;
