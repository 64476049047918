import SectionNav from "components/common/section/SectionNav/SectionNav";
import SectionContainer from "components/common/section/SectionContainer/SectionContainer";

import { UNDERLINED } from "components/constants";

const SectionWrapper = ({
	cls,
	containerCls,
	children,
	routeName,
	navTabChildren,
	upperDiv,
	tabs,
}) => {
	return (
		<SectionContainer cls={containerCls} tabType={UNDERLINED}>
			{(routeName || tabs) && (
				<SectionNav
					cls={cls}
					upperDiv={upperDiv}
					routeName={routeName}
					navTabChildren={navTabChildren}
					tabs={tabs}
				></SectionNav>
			)}
			{children}
		</SectionContainer>
	);
};

export default SectionWrapper;
