
import ChartStatus from "components/common/ChartStatus/ChartStatus";
import { totalTransactionsChartOptions } from "components/Dashboard/Providers/helpers/data";
import ChartGraph from "utils/Charts/ChartGraph/ChartGraph";

const data = [
	{
		color: "#2CC7A2",
		value: 8237,
		name: "Successful Transaction",
		cls: "recent-success-icon ",
	},
	{
		color: "#FE7D7D",
		value: 2328,
		name: "Failed Transaction",
		cls: "recent-danger-icon ",
	},
	{
		color: "#FFCB7D",
		value: 2390,
		name: "Outflow",
		cls: "recent-warning-icon",
	},
];

const TransactionStatus = () => {
	return (
		<div className="bg-white pt-4 my-5 h-100">
			<p className="title px-5">Transaction Status</p>
			<div className="flex__center">
				<ChartGraph
					option={totalTransactionsChartOptions(data)}
					styles={{
						height: "255px",
					}}
				/>
			</div>
			<div className="px-2 pb-3">
				{data.map(({ value, name, cls }) => (
					<ChartStatus value={value} name={name} cls={cls} />
				))}
			</div>
		</div>
	);
};

export default TransactionStatus;
