import { useHistory } from "react-router-dom";
import { ACTIVE } from "components/constants";
import { useState } from "react";
import { PENDING } from "utils/helpers/constants";
import StatusPill from "utils/pills/StatusPill/StatusPill";
import Table from "utils/Table/Table";
import ProviderFilters from "../common/ProviderFilters";
import {
	providerTableHeadings,
	providerFiltersDefaultValues,
	providerTableData,
} from "../helpers/data";

const AllProviders = () => {
	const history = useHistory();
	const [formValues, setFormValues] = useState({
		...providerFiltersDefaultValues,
	});

	const handleChange = ({ target: { name, value } }) =>
		handleSelect(name, value);

	const handleSelect = (name, value) => {
		console.log({ name, value });
		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	const handleSubmit = () =>
		setFormValues({ ...providerFiltersDefaultValues });

	const getWalletData = () =>
		providerTableData?.map((wallet) => ({
			transactionID: wallet?.id,
			name: wallet?.name,
			email: wallet?.email,
			amount: wallet.phone,
			status: (
				<StatusPill
					status={wallet?.status}
					success={ACTIVE}
					warning={PENDING}
				/>
			),
			createdAt: wallet?.createdAt,
		}));

	const handleRowClick = (_, rowValues) => {
		history.push(`/providers/${rowValues.transactionID}/overview`);
	};
	return (
		<>
			<ProviderFilters
				formValues={formValues}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				handleSelect={handleSelect}
			/>
			<Table
				data={getWalletData()}
				headings={providerTableHeadings}
				rowClick={handleRowClick}
			/>
		</>
	);
};

export default AllProviders;
