import timer from "assets/images/icons/timer-start.svg";
import tracker from "assets/images/icons/tracker.svg";
import more from "assets/images/icons/more-3.svg";
import timerFailed from "assets/images/icons/timer-start-danger.svg";
import "./WatcherRegistrationCard.scss";

function WatcherRegistrationCard({ watcher }) {
	return (
		<div className="watcher-reg-card p-3 mb-4">
			<div className="flex__between">
				<div className="text-desc">
					<h6>
						<span>
							<img
								src={watcher.status ? timer : timerFailed}
								alt="timer"
							/>
						</span>
						<span className="ml-4">{watcher.timer}</span>
					</h6>
					<p className="mb-0">{watcher.name}</p>
					<p className="mt-1">{watcher.email}</p>
				</div>

				<div className="icon-desc">
					<img src={more} alt="timer" className="mb-3 pointer" />
					<img src={tracker} alt="tracker" />
				</div>
			</div>
		</div>
	);
}

export default WatcherRegistrationCard;
