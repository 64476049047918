import { Progress } from "reactstrap";

const TopMerchants = ({ title, amount, progress, icon }) => {
	return (
		<div className="mb-4">
			<div className="flex__between progress-heading">
				<p className="progress-title">
					{icon} {title}
				</p>
				<p className="progress-amount">{amount}</p>
			</div>
			<Progress value={progress} color="#86A7C3" />
		</div>
	);
};

export default TopMerchants;
