import { BrowserRouter, Switch } from "react-router-dom";
// import PrivateRoutes from "../RoutesWrapper/PrivateRoutes";
import PublicRoutes from "../RoutesWrapper/PublicRoutes";
import Login from "components/Authentication/Login/Login";
import ForgotPassword from "components/Authentication/ForgotPassword/ForgotPassword";
import Dashboard from "components/Dashboard/Dashboard";
import ResetPassword from "components/Authentication/ResetPassword/ResetPassword";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoutes path="/login" component={Login} />
        <PublicRoutes path="/forgot-password" component={ForgotPassword} />
        <PublicRoutes path="/reset-password" component={ResetPassword} />

        <PublicRoutes path="/" component={Dashboard} />
        {/* <PrivateRoutes path="/" component={Dashboard} /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default App;
