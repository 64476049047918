import moment from "moment";
import { DATE_FILTERS } from "components/constants";
import { matchPath } from "react-router-dom";


export const timeRangeFormat = (obj, init) => {
	if (!obj) return null;

	const newDate = new Date(obj);
	init ? newDate.setHours(0, 0, 0, 0) : newDate.setHours(23, 59, 59, 0);
	return newDate;
};

export const dateToString = (obj) => {
	if (!obj) return null;

	const newDate = new Date(obj);
	const date = moment(newDate).format("LL");
	return date;
};

export const matchFunction = (url, pathname, exact) =>
	matchPath(pathname, {
		path: url,
		exact,
		strict: false,
	});

export const activeRoute = (path, pathname, subRoutes, exact = false) => {
	let match = matchFunction(path, pathname, exact);

	let isActive = subRoutes
		? subRoutes.find(
				(x) =>
					x.path === pathname ||
					matchFunction(x.path, pathname, exact)
		  )
		: false;

	const active = exact
		? path === pathname || isActive || match
		: path === pathname || match;

	return active ? true : false;
};

export const titleCase = (string) => {
  if (string === null || string === undefined) {
    return "";
  } else {
    let text = string.toLowerCase().split("_");
    for (var i = 0; i < text.length; i++) {
      text[i] = text[i][0].toUpperCase() + text[i].slice(1);
    }
    return text.join(" ");
  }
};

export const kebabCase = (str) =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .join("-")
    .toLowerCase();

export const toLocaleString = (value, min = 0, max = 0, minInt = 1) => {
  if (value === null || value === undefined) return 0;
  return value?.toLocaleString("en", {
    minimumIntegerDigits: minInt,
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
};

export const toCurrency = (n) => {
  if (n === null || n === undefined || n === "NaN") return 0;
  return Number(n)
    .toFixed(2)
    .replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
    });
};

export const getError = (err) => {
  const { error, message, errorData } = err?.data || {};
  let errorMessage = "";

  if (err) {
    if (error)
      errorMessage = titleCase(error.message?.replace(/[^a-z0-9+]+/gi, " "));
    else if (errorData?.message)
      errorMessage = titleCase(
        errorData?.message?.replace(/[^a-z0-9+]+/gi, " ")
      );
    else if (message)
      errorMessage = titleCase(message?.replace(/[^a-z0-9+]+/gi, " "));
    else errorMessage = "Error Processing Request. Try again.";
  } else errorMessage = "Error Processing Request. Try again.";

  return errorMessage;
};

export const truncate = (str, len = 50) => {
  if (str?.length > len) {
    return str?.substring(0, len) + "...";
  } else {
    return str;
  }
};
export const truncateText = (text, newLength) => {
  if (text.length > newLength) {
    const reverseText = text?.split("").reverse().join("");
    const extensionStartIndex = reverseText?.indexOf(".");
    const extension =
      extensionStartIndex !== -1
        ? reverseText.slice(0, extensionStartIndex).split("").reverse().join("")
        : "";
    return `${text.slice(0, newLength - 3)}...${extension}`;
  }

  return text;
};

export const setPlural = (value) => {
  if (!isNaN(value) && value !== 1) return "s";
  return "";
};

export const timeDateFormat = (obj, format = "YYYY-MM-DD") => {
  if (!obj || obj === undefined) return "";

  const timeAgo = moment(obj).fromNow();

  const newObj = new Date(obj);

  const time = moment(newObj).format("hh:mm:ss a");
  const timeOnly = moment(newObj).format("hh:mm:ss");
  const date = moment(newObj).format(format);

  return { time, date, timeOnly, timeAgo };
};

export const startOf = (date) => moment().startOf(date).format();

export const getDateRange = (dateRange, date) => {
  const to = moment(new Date()).format();
  switch (dateRange) {
    case DATE_FILTERS.DAILY:
      return { from: startOf("day"), to };
    case DATE_FILTERS.WEEKLY:
      return { from: startOf("week"), to };
    case DATE_FILTERS.MONTHLY:
      return { from: moment().subtract(1, "months").format(), to };
    case DATE_FILTERS.CUSTOM:
      return date;
    default:
      return "";
  }
};
