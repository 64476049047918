import { useState } from "react";
import DateSelect from "utils/select/DateSelect/DateSelect";
import IssuedCardStatistics from "./components/IssuedCardStatistics";
import PaymentDashboardAnalyticsCard from "../DashboardPayment/utils/Cards/PaymentDashboardAnalyticsCard";
import { infoStatsDateOptions } from "../DashboardPayment/utils/data";
import CardStatistics from "./components/CardStatistics";
import { bankingDashboardAnalyticsData } from "./components/data";
import HighestTransfers from "./components/HighestTransfers";
import TotalTransferValue from "./components/TotalTransferValue";
import VirtualAccountDistribution from "./components/VirtualAccountDistribution";

const DashboardBanking = () => {
	const [dateRange, setDateRange] = useState(
		"This Week (12 Mar, 2022 - 18 Mar, 2022)"
	);
	return (
		<div>
			{" "}
			<div className="pl-4 d-flex  mb-3">
				<DateSelect
					cls="col-9 col-sm-10"
					wrapperCls="col-9 col-sm-10 col-md-4 col-lg-3 bg-white"
					name="date-range"
					formLabel="date-range"
					onChange={(value) => setDateRange(value)}
					options={infoStatsDateOptions}
					value={dateRange}
				/>
			</div>
			<div className="row">
				{bankingDashboardAnalyticsData.map((data, i) => (
					<PaymentDashboardAnalyticsCard data={data} key={i} />
				))}
			</div>
			<div className="row">
				<div className="col-xl-6 col-md-12">
					<div className="bg-white p-4">
						<div className="title">Total Transfer Value</div>
						<TotalTransferValue />
					</div>
					<div className="bg-white p-4 my-4">
						<div className="title">Highest Transfers</div>
						<HighestTransfers />
					</div>
				</div>
				<div className="col-xl-6 col-md-12">
					<div className="row">
						<div className="col-xl-6 col-md-12">
							<div className="bg-white p-4 ">
								<div className="title">Wallet Distribution</div>
								<CardStatistics />
							</div>
						</div>
						<div className="col-xl-6 col-md-12">
							<div className="bg-white p-4 pt-4 ">
								<div className="title">Card Statistics</div>
								<CardStatistics />
							</div>
						</div>
					</div>
					<div className="row my-4">
						<div className="col-xl-6 col-md-12">
							<div className="bg-white p-4 ">
								<div className="title">
									Virtual Account Distribution
								</div>
								<VirtualAccountDistribution />
							</div>
						</div>
						<div className="col-xl-6 col-md-12">
							<div className="bg-white p-4 pt-4 ">
								<div className="title">
									Isssued Card Statistics
								</div>
								<IssuedCardStatistics />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashboardBanking;
