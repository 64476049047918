import SectionWrapper from "components/common/section/SectionWrapper/SectionWrapper";
import DetailsHeader from "components/common/Details/DetailsHeader/DetailsHeader";
import { ReactComponent as LinkIcon } from "assets/images/icons/link.svg";
import getTabRoutes from "../../helpers/tabs";
import { useHistory, useParams } from "react-router-dom";
import ProviderOverviewChart from "./ProviderOverviewChart";
import { providerResponse } from "../../helpers/data";
import ProviderOverviewDetails from "./ProviderOverviewDetails";

function ProviderOverview() {
  const history = useHistory();
  const { id } = useParams();
	return (
		<SectionWrapper
			cls="pb-0"
			containerCls="d-flex flex-wrap"
			routeName={
				<DetailsHeader
					detailID={id}
					icon={<LinkIcon className="me-3" />}
					IDName="Provider Id"
					returnAction={() => history.push("/providers")}
					tabName="Providers"
				/>
			}
			tabs={getTabRoutes(id)}
			navTabChildren={
				<div className="d-flex">
					<button className="btn btn--sm btn--secondary--bordered me-4"></button>
					<button className="btn btn--sm btn--secondary--bordered me-4"></button>
				</div>
			}
			upperDiv={<ProviderOverviewDetails res={providerResponse} />}
		>
			<div className="col-12 ps-lg-5 pt-5 pb-lg-5 pe-lg-5 overflow-md-x d-flex d-lg-block">
				<ProviderOverviewChart />
			</div>
		</SectionWrapper>
	);
}

export default ProviderOverview;
