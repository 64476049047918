// import { toLower } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import SectionWrapper from "components/common/section/SectionWrapper/SectionWrapper";
import DetailsHeader from "components/common/Details/DetailsHeader/DetailsHeader";
import { ReactComponent as LinkIcon } from "assets/images/icons/link.svg";
import getTabRoutes from "../../helpers/tabs";
import PaymentDisputesTable from "./PaymentDisputesTable";

function PaymentDisputes() {
	const history = useHistory();
	const { id } = useParams();
	return (
		<SectionWrapper
			cls="pb-0"
			containerCls="d-flex flex-wrap"
			routeName={
				<DetailsHeader
					detailID={id}
					icon={<LinkIcon className="me-3" />}
					IDName="Wallet Id"
					returnAction={() => history.push("/banking/wallets")}
					tabName="Banking Wallets"
				/>
			}
			tabs={getTabRoutes(id)}
			navTabChildren={
				<div className="d-flex">
					<button className="btn btn--sm btn--secondary--bordered me-4"></button>
					<button className="btn btn--sm btn--secondary--bordered me-4"></button>
				</div>
			}
		>
			<div className="col-lg-12 col-12" id="border-right-bottom--gray">
				<PaymentDisputesTable />
			</div>
		</SectionWrapper>
	);
}

export default PaymentDisputes;
