import { useState } from "react";
import { ReactComponent as Trash } from "assets/images/icons/trash.svg";
import Table from "utils/Table/Table";
import ProviderFilters from "../../common/ProviderFilters";
import {
	providerFiltersDefaultValues,
	providerKeysTableHeadings,
	providerKeysTableData,
} from "../../helpers/data";

const ProviderKeysTable = () => {
	const [formValues, setFormValues] = useState({
		...providerFiltersDefaultValues,
	});

	const handleChange = ({ target: { name, value } }) =>
		handleSelect(name, value);

	const handleSelect = (name, value) => {
		console.log({ name, value });
		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	const handleSubmit = () =>
		setFormValues({ ...providerFiltersDefaultValues });

	const getWalletData = () => {
		return providerKeysTableData.map(data => ({
			...data, action: <Trash className="pointer" />
		}))
	};

	const handleRowClick = (_, rowValues) => {
		// history.push(`/providers/${rowValues.transactionID}/overview`);
	};
	return (
		<>
			<div className="position-relative">
				<ProviderFilters
					formValues={formValues}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					handleSelect={handleSelect}
				/>
				<Table
					data={getWalletData()}
					headings={providerKeysTableHeadings}
					rowClick={handleRowClick}
				/>
			</div>
		</>
	);
};

export default ProviderKeysTable;
