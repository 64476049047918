import ChartGraph from "utils/Charts/ChartGraph/ChartGraph";
import { newRegistrationOption } from "./data";

const NewRegistrations = () => {
	return (
		<div className="pt-1">
			<ChartGraph
				option={newRegistrationOption}
				styles={{
					height: "361px",
				}}
			/>
		</div>
	);
};

export default NewRegistrations;
