import { ReactComponent as BuildingIcon } from "assets/images/icons/buildings.svg";
import providerImg from "assets/images/user-avatar.jpg";
import { ACTIVE, INACTIVE } from "components/constants";
import StatusPill from "utils/pills/StatusPill/StatusPill";
import { profileInformationResponse } from "../../helpers/data";

function ProfileInformation() {
	return (
		<div className="row">
			<div className="col-md-2 mb-4 px-3">
				<img src={providerImg} alt="avatar" width={180} />
			</div>
			<div className="col-lg-6">
				<div className="col-md-10 info__section">
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Role
						</p>
						<p className="value justify-content-end">
							{profileInformationResponse?.role}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Status
						</p>
						<p className="value justify-content-end">
							<StatusPill
								status={profileInformationResponse?.status}
								success={ACTIVE}
								warning={INACTIVE}
							/>
						</p>
					</section>

					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> User ID
						</p>
						<p className="value justify-content-end">
							{profileInformationResponse?.userId}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> First Name
						</p>
						<p className="value justify-content-end">
							{profileInformationResponse?.firstName}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Last Name
						</p>
						<p className="value justify-content-end">
							{profileInformationResponse?.lastName}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Email
						</p>
						<p className="value justify-content-end">
							{profileInformationResponse?.email}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Phone Number
						</p>
						<p className="value justify-content-end">
							{profileInformationResponse?.phone}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Date Created
						</p>
						<p className="value justify-content-end">
							{profileInformationResponse?.createdAt}
						</p>
					</section>
				</div>
			</div>
		</div>
	);
}

export default ProfileInformation;
