import "./CustomInputBox.scss";

const CustomInputBox = ({
  type = "text",
  name,
  label,
  placeholder,
  value,
  readonly,
  error,
  prefixIcon,
  suffixIcon,
  onChange,
  onFocus,
  required,
  otherClass,
  disabled,
  ...rest
}) => {
  const isError = error ? "error" : " ";
  const isRequired = required ? "required" : " ";
  const isDisabled = disabled ? "disabled" : " ";

  return (
    <div className={`form-group ${otherClass}`}>
      {label && (
        <label htmlFor={name} className={`form-group-label ${isRequired}`}>
          {label}
        </label>
      )}
      <div
        className={`input-wrapper d-flex align-items-center ${isDisabled} ${isError} `}
      >
        {prefixIcon && <div className="px-4">{prefixIcon}</div>}
        <input
          type={type}
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          readOnly={readonly}
          autoComplete="off"
          disabled={disabled}
          onChange={onChange}
          {...rest}
        />
        {suffixIcon && <div className="px-4">{suffixIcon}</div>}
      </div>
      {typeof error === "string" && (
        <small className="input-text-error">{error}</small>
      )}
    </div>
  );
};

export default CustomInputBox;
