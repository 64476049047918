import { useEffect, useState } from "react";
import { Progress } from "reactstrap";

import EyeOnIcon from "assets/images/icons/eye-on.svg";
import EyeOffIcon from "assets/images/icons/eye-off.svg";
import InfoCircleError from "assets/images/icons/info-circle-error.svg";

export default function CustomPassword({
  id,
  name = "password",
  cls,
  value,
  error,
  label = "Password",
  onChange,
  placeholder = "Password",
  showPasswordStrength,
  required,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const isError = error ? "error" : "";
  const isRequired = required ? "required" : "";
  const strongRegExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  useEffect(() => {
    if (/[a-z]/.test(value)) setPasswordStrength(25);
    if (/(?=.*?[0-9])/.test(value)) setPasswordStrength(55);
    if (strongRegExp.test(value) && value.length >= 8) setPasswordStrength(80);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={`form-group ${cls}`}>
      {label && (
        <label htmlFor={name} className={`form-group-label ${isRequired}`}>
          {label}
        </label>
      )}

      <div className="input-container-wrapper">
        <div className={`input-wrapper ${isError} d-flex align-items-center`}>
          <input
            className="w-100"
            type={showPassword ? "text" : "password"}
            id={id}
            name={name}
            placeholder={placeholder}
            value={value}
            autoComplete="off"
            onChange={onChange}
            {...rest}
          />

          <div
            className={`px-2 pointer ${showPassword ? "show__password" : ""}`}
          >
            {showPassword ? (
              <img
                src={EyeOnIcon}
                alt="hide password icon"
                onClick={() => setShowPassword((prev) => !prev)}
              />
            ) : (
              <img
                src={EyeOffIcon}
                alt="show password icon"
                onClick={() => setShowPassword((prev) => !prev)}
              />
            )}
          </div>
        </div>

        {typeof error === "string" && error && (
          <small className="input-text-error">
            <InfoCircleError className="me-2" />
            {error}
          </small>
        )}
      </div>

      {value && showPasswordStrength && (
        <div className="pt-3 w-100 flex__between flex-wrap pt-3">
          <Progress
            className={`col-6 password-strength ${
              passwordStrength <= 33
                ? "danger"
                : passwordStrength >= 67
                ? "success"
                : "warning"
            }`}
            color={
              passwordStrength <= 33
                ? "danger"
                : passwordStrength >= 67
                ? "success"
                : "warning"
            }
            value={passwordStrength}
          />
          <span className="light-text-gray">
            {`${
              passwordStrength <= 33
                ? "Weak"
                : passwordStrength >= 67
                ? "Strong"
                : "Fair"
            } Password`}
          </span>
        </div>
      )}
    </div>
  );
}
