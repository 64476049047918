import { toLower } from "lodash";
const basePath = "/providers";

const getTabRoutes = (walletId) => {
	return [
		{
			label: "Provider Overview",
			route: `${basePath}/${toLower(walletId)}/overview`,
		},
		{
			label: "Services",
			route: `${basePath}/${toLower(walletId)}/services`,
		},
		{
			label: "Pricing",
			route: `${basePath}/${toLower(walletId)}/pricing`,
		},
		{
			label: "Integration",
			route: `${basePath}/${toLower(walletId)}/integration`,
		},
	];
};

export default getTabRoutes;
