import { ReactComponent as WatcherIcon } from "assets/images/icons/eye.svg";
import { ReactComponent as DashboardIcon } from "assets/images/icons/dashboard.svg";
import { ReactComponent as TransactionsIcon } from "assets/images/icons/transaction.svg";
import { ReactComponent as BankingIcon } from "assets/images/icons/bank.svg";
import { ReactComponent as AggregatorIcon } from "assets/images/icons/aggregator.svg";
import { ReactComponent as MerchantIcon } from "assets/images/icons/merchant.svg";
import { ReactComponent as CustomerIcon } from "assets/images/icons/customer.svg";
import { ReactComponent as ProviderIcon } from "assets/images/icons/provider.svg";
import { ReactComponent as PricingIcon } from "assets/images/icons/pricing.svg";
import { ReactComponent as ReportIcon } from "assets/images/icons/report.svg";
import { ReactComponent as AuditIcon } from "assets/images/icons/audit.svg";
import { ReactComponent as UserIcon } from "assets/images/icons/user.svg";
import { ReactComponent as SettingIcon } from "assets/images/icons/setting.svg";

import WatcherRegistrations from "components/Dashboard/Watcher/WatcherRegistrations/WatcherRegistrations";
import WatcherDashboard from "components/Dashboard/Watcher/WatcherDashboard/WatcherDashboard";
import WatcherTransactions from "components/Dashboard/Watcher/WatcherTransactions/WatcherTransactions";

import DashboardPayment from "components/Dashboard/DashContent/DashboardPayment/DashboardPayment";
import DashboardBanking from "components/Dashboard/DashContent/DashboardBanking/DashboardBanking";
import DashboardAggregators from "components/Dashboard/DashContent/DashboardAggregators/DashboardAggregators";
import DashboardMerchants from "components/Dashboard/DashContent/DashboardMerchants/DashboardMerchants";

import PaymentTransactions from "components/Dashboard/Transactions/PaymentTransactions/PaymentTransactions";
import PaymentLinkTransactions from "components/Dashboard/Transactions/PaymentLinkTransactions/PaymentLinkTransactions";
import PageTransactions from "components/Dashboard/Transactions/PageTransactions/PageTransactions";
import InvoiceTransactions from "components/Dashboard/Transactions/InvoiceTransactions/InvoiceTransactions";
import SubscriptionTransactions from "components/Dashboard/Transactions/SubscriptionTransactions/SubscriptionTransactions";
import PayoutTransactions from "components/Dashboard/Transactions/PayoutTransactions/PayoutTransactions";
import TerminalTransactions from "components/Dashboard/Transactions/TerminalTransactions/TerminalTransactions";
import QRCodesTransactions from "components/Dashboard/Transactions/QRCodesTransactions/QRCodesTransactions";
import SplitPaymentTransactions from "components/Dashboard/Transactions/SplitPaymentTransactions/SplitPaymentTransactions";

import BankingWallets from "components/Dashboard/Banking/BankingWallets/BankingWallets";
import BankingVirtualAccounts from "components/Dashboard/Banking/BankingVirtualAccounts/BankingVirtualAccounts";
import BankingTransfers from "components/Dashboard/Banking/BankingTransfers/BankingTransfers";
import BankingCards from "components/Dashboard/Banking/BankingCards/BankingCards";

import AllAggregators from "components/Dashboard/Aggregator/AllAggregators/AllAggregators";

import AllMerchants from "components/Dashboard/Merchants/AllMerchants/AllMerchants";
import WhiteLabelledMerchants from "components/Dashboard/Merchants/WhiteLabelledMerchants/WhiteLabelledMerchants";

import AllCustomers from "components/Dashboard/Customers/AllCustomers/AllCustomers";
import AllProviders from "components/Dashboard/Providers/AllProviders/AllProviders";
import History from "components/Dashboard/Reports/History/History";
import UserAuditLogs from "components/Dashboard/AuditLogs/UserAuditLogs/UserAuditLogs";
import Pricing from "components/Dashboard/Pricing/Pricing";
import AllUsers from "components/Dashboard/Users/AllUsers/AllUsers";

import PaymentSettings from "components/Dashboard/Settings/PaymentSettings/PaymentSettings";
import BankingSettings from "components/Dashboard/Settings/BankingSettings/BankingSettings";
import ServiceSettings from "components/Dashboard/Settings/ServiceSettings/ServiceSettings";
import AccountSettings from "components/Dashboard/Settings/AccountSettings/AccountSettings";
import WalletInfo from "components/Dashboard/Banking/BankingWallets/WalletInfo/WalletInfo";
import WalletCard from "components/Dashboard/Banking/BankingWallets/WalletCard/WalletCard";
import Subscriptions from "components/Dashboard/Banking/BankingWallets/Subscriptions/Subscriptions";
import PaymentDisputes from "components/Dashboard/Banking/BankingWallets/PaymentDisputes/PaymentDisputes";
import AccountDetails from "components/Dashboard/Banking/BankingVirtualAccounts/AccountDetails/AccountDetails";
import CardDetails from "components/Dashboard/Banking/BankingCards/CardDetails/CardDetails";
import ProviderOverview from "components/Dashboard/Providers/ProviderDetails/ProviderOverview/ProviderOverview";
import ProviderServices from "components/Dashboard/Providers/ProviderDetails/ProviderServices/ProviderServices";
import ProviderPricing from "components/Dashboard/Providers/ProviderDetails/ProviderPricing/ProviderPricing";
import ProviderIntegrations from "components/Dashboard/Providers/ProviderDetails/ProviderIntegrations/ProviderIntegrations";
import UserDetails from "components/Dashboard/Users/UserDetails/UserDetails";

const DefaultView = ({ title }) => (
	<div className="container m-5">
		<h4>{title}</h4>
	</div>
);

const routes = [
	{
		name: "Watcher",
		path: "/watcher",
		secPath: "/watcher/registrations",
		component: DefaultView,
		icon: WatcherIcon,
		tabs: [
			{
				name: "Registrations",
				path: "/watcher/registrations",
				component: WatcherRegistrations,
				exact: true,
			},
			{
				name: "Transactions",
				path: "/watcher/transactions",
				component: WatcherTransactions,
				exact: true,
			},
			{
				name: "Dashboards",
				path: "/watcher/dashboards",
				component: WatcherDashboard,
				exact: true,
			},
		],
	},
	{
		name: "Dashboard",
		path: "/dashboard",
		secPath: "/dashboard/payments",
		component: DefaultView,
		icon: DashboardIcon,
		tabs: [
			{
				name: "Payments",
				path: "/dashboard/payments",
				component: DashboardPayment,
				exact: true,
			},
			{
				name: "Banking",
				path: "/dashboard/banking",
				component: DashboardBanking,
				exact: true,
			},
			{
				name: "Aggregators",
				path: "/dashboard/aggregators",
				component: DashboardAggregators,
				exact: true,
			},
			{
				name: "Merchants",
				path: "/dashboard/merchants",
				component: DashboardMerchants,
				exact: true,
			},
		],
	},
	{
		name: "Transactions",
		path: "/transactions",
		secPath: "/transactions/payments",
		component: DefaultView,
		icon: TransactionsIcon,
		tabs: [
			{
				name: "Payments",
				path: "/transactions/payments",
				component: PaymentTransactions,
				exact: true,
			},
			{
				name: "Links",
				path: "/transactions/payment-links",
				component: PaymentLinkTransactions,
				exact: true,
			},
			{
				name: "Pages",
				path: "/transactions/pages",
				component: PageTransactions,
				exact: true,
			},
			{
				name: "Invoices",
				path: "/transactions/invoices",
				component: InvoiceTransactions,
				exact: true,
			},
			{
				name: "Subscriptions",
				path: "/transactions/subscriptions",
				component: SubscriptionTransactions,
				exact: true,
			},
			{
				name: "Payouts",
				path: "/transactions/payouts",
				component: PayoutTransactions,
				exact: true,
			},
			{
				name: "Terminal",
				path: "/transactions/terminals",
				component: TerminalTransactions,
				exact: true,
			},
			{
				name: "QR Codes",
				path: "/transactions/qr-codes",
				component: QRCodesTransactions,
				exact: true,
			},
			{
				name: "Split Payments",
				path: "/transactions/split-payments",
				component: SplitPaymentTransactions,
				exact: true,
			},
		],
	},
	{
		name: "Banking",
		path: "/banking",
		secPath: "/banking/wallets",
		component: DefaultView,
		icon: BankingIcon,
		tabs: [
			{
				name: "Wallets",
				path: "/banking/wallets",
				component: BankingWallets,
				exact: true,
			},
			{
				name: "Virtual Accounts",
				path: "/banking/virtual-accounts",
				component: BankingVirtualAccounts,
				exact: true,
			},
			{
				name: "Banking",
				path: "/banking/virtual-accounts/:id/account-details",
				component: AccountDetails,
				icon: BankingIcon,
				hide: true,
			},
			{
				name: "Banking",
				path: "/banking/cards/:id/card-details",
				component: CardDetails,
				icon: BankingIcon,
				hide: true,
			},
			{
				name: "Transfers",
				path: "/banking/transfers",
				component: BankingTransfers,
				exact: true,
			},
			{
				name: "Cards",
				path: "/banking/cards",
				component: BankingCards,
				exact: true,
			},
			{
				name: "Banking",
				path: "/banking/wallets/:id/wallet-information",
				component: WalletInfo,
				icon: BankingIcon,
				hide: true,
			},
			{
				name: "Banking",
				path: "/banking/wallets/:id/cards",
				component: WalletCard,
				icon: BankingIcon,
				hide: true,
			},
			{
				name: "Banking",
				path: "/banking/wallets/:id/subscriptions",
				component: Subscriptions,
				icon: BankingIcon,
				hide: true,
			},
			{
				name: "Banking",
				path: "/banking/wallets/:id/payment-disputes",
				component: PaymentDisputes,
				icon: BankingIcon,
				hide: true,
			},
		],
	},
	{
		name: "Aggregator",
		path: "/aggregator",
		secPath: "/aggregator/all",
		component: DefaultView,
		icon: AggregatorIcon,
		tabs: [
			{
				name: "All Aggregators",
				path: "/aggregator/all",
				component: AllAggregators,
				exact: true,
			},
		],
	},
	{
		name: "Merchants",
		path: "/merchants",
		secPath: "/merchants/all",
		component: DefaultView,
		icon: MerchantIcon,
		tabs: [
			{
				name: "All Merchants",
				path: "/merchants/all",
				component: AllMerchants,
				exact: true,
			},
			{
				name: "White-label",
				path: "/merchants/White-label",
				component: WhiteLabelledMerchants,
				exact: true,
			},
		],
	},
	{
		name: "Customers",
		path: "/customers",
		secPath: "/customers/all",
		component: DefaultView,
		icon: CustomerIcon,
		tabs: [
			{
				name: "All Customers",
				path: "/customers/all",
				component: AllCustomers,
				exact: true,
			},
		],
	},
	{
		name: "Providers",
		path: "/providers",
		secPath: "/providers",
		component: DefaultView,
		icon: ProviderIcon,
		tabs: [
			{
				name: "All Providers",
				path: "/providers",
				component: AllProviders,
				exact: true,
			},
			{
				name: "Provider Overview",
				path: "/providers/:id/overview",
				component: ProviderOverview,
				exact: true,
				hide: true,
			},
			{
				name: "Provider Services",
				path: "/providers/:id/services",
				component: ProviderServices,
				exact: true,
				hide: true,
			},
			{
				name: "Provider Pricing",
				path: "/providers/:id/pricing",
				component: ProviderPricing,
				exact: true,
				hide: true,
			},
			{
				name: "Provider Integration",
				path: "/providers/:id/integration",
				component: ProviderIntegrations,
				exact: true,
				hide: true,
			},
		],
	},
	{
		name: "Pricing",
		path: "/pricing",
		component: Pricing,
		exact: true,
		icon: PricingIcon,
	},
	{
		name: "Reports",
		path: "/reports",
		secPath: "/reports/history",
		component: DefaultView,
		icon: ReportIcon,
		tabs: [
			{
				name: "History",
				path: "/reports/history",
				component: History,
				exact: true,
			},
		],
	},
	{
		name: "Audit Logs",
		path: "/audit-logs",
		secPath: "/audit-logs/users",
		component: DefaultView,
		icon: AuditIcon,
		tabs: [
			{
				name: "Users",
				path: "/audit-logs/users",
				component: UserAuditLogs,
				exact: true,
			},
		],
	},
	{
		name: "Users",
		path: "/users",
		secPath: "/users/all",
		component: DefaultView,
		icon: UserIcon,
		tabs: [
			{
				name: "Users",
				path: "/users/all",
				component: AllUsers,
				exact: true,
			},
			{
				name: "Users Details",
				path: "/users/:id/details",
				component: UserDetails,
				exact: true,
				hide: true,
			},
		],
	},
	{
		name: "Settings",
		path: "/settings",
		secPath: "/settings/payments",
		component: DefaultView,
		icon: SettingIcon,
		tabs: [
			{
				name: "Payments",
				path: "/settings/payments",
				component: PaymentSettings,
				exact: true,
			},
			{
				name: "Banking",
				path: "/settings/banking",
				component: BankingSettings,
				exact: true,
			},
			{
				name: "Services",
				path: "/settings/services",
				component: ServiceSettings,
				exact: true,
			},
			{
				name: "Account",
				path: "/settings/account",
				component: AccountSettings,
				exact: true,
			},
		],
	},
];

export default routes;
