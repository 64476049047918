import { useState } from "react";
import Table from "utils/Table/Table";
import PermissionModal from "../../common/modals/PermissionModal";
import UsersFilters from "../../common/UsersFilters";
import {
	userFiltersDefaultValues,
	permissionTableData,
} from "../../helpers/data";

const Permissions = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [currentRowValues, setCurrentRowValues] = useState({});
	const [formValues, setFormValues] = useState({
		...userFiltersDefaultValues,
	});

	const toggleModal = () => setIsOpen((prev) => !prev);

	const handleChange = ({ target: { name, value } }) =>
		handleSelect(name, value);

	const handleSelect = (name, value) => {
		console.log({ name, value });
		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	const handleSubmit = () => setFormValues({ ...userFiltersDefaultValues });

	const getActivityData = () => {
		return permissionTableData.map((data) => ({
			...data,
			edit: (
				<button type="submit" className="btn btn--primary btn--xm ms-2">
					Edit Customer
				</button>
			),
			delete: (
				<button type="submit" className="btn btn--primary btn--xm ms-2">
					Delete Customer
				</button>
			),
			delete1: (
				<button type="submit" className="btn btn--primary btn--xm ms-2">
					Delete Customer
				</button>
			),
			delete2: (
				<button type="submit" className="btn btn--primary btn--xm ms-2">
					Delete Customer
				</button>
			),
		}));
	};

	const handleRowClick = (_, rowValues) => {
		const permissionData = permissionTableData?.find(
			(transaction) => transaction?.id === rowValues?.id
		);
		setCurrentRowValues({
			...rowValues,
			...permissionData,
		});
		toggleModal();
	};
	return (
		<>
			<div className="position-relative">
				<UsersFilters
					formValues={formValues}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					handleSelect={handleSelect}
				/>
				<Table data={getActivityData()} rowClick={handleRowClick} />
				{isOpen && (
					<PermissionModal
						toggle={toggleModal}
						payment={currentRowValues}
						IDTabName={`Permission Details`}
					/>
				)}
			</div>
		</>
	);
};

export default Permissions;
