export const merchantDashboardAnalyticsData = [
	{
		title: "Registered Merchants",
		amount: "839,394,394",
		increase: "+6.48%",
	},
	{
		title: "Live Merchants",
		amount: "839,394,394",
		increase: "+6.48%",
	},
	{
		title: "Authenticated Mechnats",
		amount: "839,394,394",
		increase: "+6.48%",
	},
	{
		title: "New Merchants",
		amount: "839,394,394",
		increase: "+6.48%",
	},
];

export const locationsData = [
	{
		title: "Lagos, Nigeria",
		amount: "7,450",
		progress: 90,
	},
	{
		title: "Ibadan, Nigeria",
		amount: "7,450",
		progress: 50,
	},
	{
		title: "Portharcourt, Nigeria",
		amount: "7,450",
		progress: 20,
	},
	{
		title: "Abuja, Nigeria",
		amount: "7,450",
		progress: 50,
	},
	{
		title: "Abuja, Nigeria",
		amount: "7,450",
		progress: 50,
	},
];

export const newRegistrationOption = {
	xAxis: {
		type: "category",
		grid: {
			bottom: 0,
			containLabel: true,
			left: 0,
			right: 0,
			top: 0,
		},
		data: [
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
		],
		axisLabel: {
			show: true,
		},
		axisTick: {
			show: false,
		},
		splitLine: {
			show: false,
		},
		axisLine: {
			show: false,
		},
	},
	yAxis: {
		type: "value",
		axisLabel: {
			formatter: "{value} K",
		},
		axisTick: {
			show: false,
		},
		axisLine: {
			show: false,
		},
		axisPointer: {
			show: true,
			type: "none",
			color: "#E2E4E5",
		},
		data: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20],
	},
	series: [
		{
			data: [2, 6, 7, 8, 11, 16, 18],
			type: "bar",
			name: "M",
			barWidth: 39,
			stack: "total",
			color: "#47DEC3",
		},
	],
};
