import ChartGraph from "utils/Charts/ChartGraph/ChartGraph";
import { totalTransferValueOptions } from "./data";

const TotalTransferValue = () => {
	return (
		<div className="pt-1">
			<ChartGraph
				option={totalTransferValueOptions}
				styles={{
					height: "461px",
				}}
			/>
		</div>
	);
};

export default TotalTransferValue;
