import DatePicker from "react-datepicker";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right.svg";
import "react-datepicker/dist/react-datepicker.css";

export default function DateRangePicker({
  onSubmit,
  endDate,
  startDate,
  maxDate,
  handleChange,
}) {
  return (
    <div className="custom__date shadow bg-white">
      <div className="d-flex">
        <DatePicker
          selected={startDate}
          onChange={(e) => handleChange("from", e)}
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate || new Date()}
          inline
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
        <div className="p-3" />
        <DatePicker
          selected={endDate}
          onChange={(e) => handleChange("to", e)}
          startDate={startDate}
          endDate={endDate}
          maxDate={maxDate}
          inline
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </div>
      <div className="flex__center mb-4">
        <ArrowRight className="primary pointer" onClick={onSubmit} />
      </div>
    </div>
  );
}
