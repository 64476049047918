import { highRiskTransOption } from "../utils/data";
import ChartGraph from "utils/Charts/ChartGraph/ChartGraph";

const HighRiskTransactions = () => {
	return (
		<ChartGraph
			option={highRiskTransOption}
			styles={{
				height: "359px",
			}}
		/>
	);
};

export default HighRiskTransactions;
