import { ReactComponent as SuccessUp } from "assets/images/icons/success-up.svg";
import { ReactComponent as Money } from "assets/images/icons/money.svg";
import "./PaymentDashboardAnalyticsCard.scss";

const PaymentDashboardAnalyticsCard = ({ data }) => {
	return (
		<div className="col-xl-3 col-md-6">
			<div className="watcher-reg-card p-3 mb-4 py-4">
				<div className="flex__between">
					<div className="text-desc">
						<h6>
							<span>
								<Money />
							</span>
							<span className="ml-4">{data.title}</span>
						</h6>
						<h2 className="mt-4">{data.amount}</h2>
					</div>
					<div className="icon-desc">
						<div className="spike-wrapper">
							<SuccessUp />
							<span>{data.increase}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentDashboardAnalyticsCard;
