import styledComponents from "styled-components";

export const ProgressBarWrapper = styledComponents.div`
  ${({ height, completed, borderRadius, width, bgcolor }) => `
    height: ${height ? height : "40px"};
	position: relative;
	width: ${width ? width : "100%"};
	background-color: #F8FBFE;
    borderRadius: ${borderRadius ? borderRadius : "2px"};
    .filler{
        height: 100%;
		width: ${completed}%;
		background: ${bgcolor};
		border-radius: "inherit";
	
    }
    .label{
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        position: absolute;
        left : 2%;
         top: 20%;
         text-transform: capitalize;
    }
    .value{
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: ${completed === 100 ? "#FFFFFF" : "#39444F"};
        position: absolute;
        right : 2%;
        top: 20%;
    }
`}
`;
