export const bankingWalletsFiltersDefaultValues = {
	PaymentID: "",
	wallet: false,
	success: false,
	pending: false,
	failed: false,
	dateCreated: null,
};

export const bankingWalletsTableHeadings = [
	"Wallet ID",
	"Customer ID",
	"Name",
	"Wallet Type",
	"Merchant No",
	"Balance",
	"Trans Cost",
	"Date Created",
];
export const virtualAccountTableHeadings = [
	"Reference ID",
	"Owner ID",
	"Customer Type",
	"Account Name",
	"Bank",
	"Number",
	"Date Created",
];
export const bankingTransferTableHeadings = [
	"Reference ID",
	"Name",
	"Recipient Acount",
	"Bank Name",
	"Amount",
	"Charges",
	"Status",
	"Date Created",
];
export const bankingCardsTableHeadings = [
	"Reference ID",
	"Owner ID",
	"Card Issued",
	"Card Owner",
	"Card Type",
	"Issuer Name",
	"Number",
	"Date Created",
];
export const CardsTableHeadings = [
	"Transaction ID",
	"Amount",
	"Card Type",
	"Channel",
	"Status",
	"Transaction Date",
];
export const subscriptionHeadings = [
	"Plan ID",
	"Plan Name",
	"Type",
	"Interval",
	"Amount",
	"Charges",
	"Status",
	"Date Created",
];
export const walletResponse = {
	walletId: "WT27492294807",
	name: "Osinachukwu Blessed-man",
	bank: "Access Bank",
	number: "816398738",
	currency: "NGN",
	createdAt: "12 Jan, 2022, 13:54:05 GMT",
};
export const cardResponse = {
	cardId: "WT27492294807",
	cardType: "Virtual",
	card: "Master Card",
	cardHolder: "Jeremy Smith",
	limit: 67783444.87,
	currency: "NGN",
	createdAt: "12 Jan, 2022, 13:54:05 GMT",
	expiredAt: "12 Jan, 2022, 13:54:05 GMT",
};
export const bankingWalletsTableData = [
	{
		walletId: "WT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		walletType: "Customer",
		merchantNo: "MCT7816398738",
		balance: 67783444.87,
		transCost: 50,
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "WT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		walletType: "Customer",
		merchantNo: "MCT7816398738",
		balance: 67783444.87,
		transCost: 50,
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "WT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		walletType: "Customer",
		merchantNo: "MCT7816398738",
		balance: 67783444.87,
		transCost: 50,
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "WT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		walletType: "Customer",
		merchantNo: "MCT7816398738",
		balance: 67783444.87,
		transCost: 50,
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "WT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		walletType: "Customer",
		merchantNo: "MCT7816398738",
		balance: 67783444.87,
		transCost: 50,
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "WT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		walletType: "Customer",
		merchantNo: "MCT7816398738",
		balance: 67783444.87,
		transCost: 50,
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "WT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		walletType: "Customer",
		merchantNo: "MCT7816398738",
		balance: 67783444.87,
		transCost: 50,
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "WT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		walletType: "Customer",
		merchantNo: "MCT7816398738",
		balance: 67783444.87,
		transCost: 50,
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "WT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		walletType: "Customer",
		merchantNo: "MCT7816398738",
		balance: 67783444.87,
		transCost: 50,
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "WT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		walletType: "Customer",
		merchantNo: "MCT7816398738",
		balance: 67783444.87,
		transCost: 50,
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "WT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		walletType: "Customer",
		merchantNo: "MCT7816398738",
		balance: 67783444.87,
		transCost: 50,
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "WT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		walletType: "Customer",
		merchantNo: "MCT7816398738",
		balance: 67783444.87,
		transCost: 50,
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
];
export const virtualAccountTableData = [
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		walletType: "Merchant",
		name: "Osinachukwu Blessed-man",
		bank: "Access Bank",
		number: "816398738",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		walletType: "Merchant",
		name: "Osinachukwu Blessed-man",
		bank: "Access Bank",
		number: "816398738",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		walletType: "Merchant",
		name: "Osinachukwu Blessed-man",
		bank: "Access Bank",
		number: "816398738",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		walletType: "Merchant",
		name: "Osinachukwu Blessed-man",
		bank: "Access Bank",
		number: "816398738",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		walletType: "Merchant",
		name: "Osinachukwu Blessed-man",
		bank: "Access Bank",
		number: "816398738",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		walletType: "Merchant",
		name: "Osinachukwu Blessed-man",
		bank: "Access Bank",
		number: "816398738",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		walletType: "Merchant",
		name: "Osinachukwu Blessed-man",
		bank: "Access Bank",
		number: "816398738",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		walletType: "Merchant",
		name: "Osinachukwu Blessed-man",
		bank: "Access Bank",
		number: "816398738",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		walletType: "Merchant",
		name: "Osinachukwu Blessed-man",
		bank: "Access Bank",
		number: "816398738",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		walletType: "Merchant",
		name: "Osinachukwu Blessed-man",
		bank: "Access Bank",
		number: "816398738",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		walletType: "Merchant",
		name: "Osinachukwu Blessed-man",
		bank: "Access Bank",
		number: "816398738",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
];
export const bankingTransferTableData = [
	{
		referenceId: "VT27492294807",
		name: "Osinachukwu Blessed-man",
		account: "8710878744",
		bank: "Access Bank",
		amount: 23456765,
		charges: 248,
		status: "success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		referenceId: "VT27492294807",
		name: "Osinachukwu Blessed-man",
		account: "8710878744",
		bank: "Access Bank",
		amount: 23456765,
		charges: 248,
		status: "success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		referenceId: "VT27492294807",
		name: "Osinachukwu Blessed-man",
		account: "8710878744",
		bank: "Access Bank",
		amount: 23456765,
		charges: 248,
		status: "success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		referenceId: "VT27492294807",
		name: "Osinachukwu Blessed-man",
		account: "8710878744",
		bank: "Access Bank",
		amount: 23456765,
		charges: 248,
		status: "success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		referenceId: "VT27492294807",
		name: "Osinachukwu Blessed-man",
		account: "8710878744",
		bank: "Access Bank",
		amount: 23456765,
		charges: 248,
		status: "success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		referenceId: "VT27492294807",
		name: "Osinachukwu Blessed-man",
		account: "8710878744",
		bank: "Access Bank",
		amount: 23456765,
		charges: 248,
		status: "success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		referenceId: "VT27492294807",
		name: "Osinachukwu Blessed-man",
		account: "8710878744",
		bank: "Access Bank",
		amount: 23456765,
		charges: 248,
		status: "success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		referenceId: "VT27492294807",
		name: "Osinachukwu Blessed-man",
		account: "8710878744",
		bank: "Access Bank",
		amount: 23456765,
		charges: 248,
		status: "success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
];
export const bankingCardsTableData = [
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		cardIssued: "Visa Card",
		cardType: "Physical Card",
		issuerId: "MT435783",
		issuerName: "King Cab Auto Service",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		cardIssued: "Visa Card",
		cardType: "Physical Card",
		issuerId: "MT435783",
		issuerName: "King Cab Auto Service",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		cardIssued: "Visa Card",
		cardType: "Physical Card",
		issuerId: "MT435783",
		issuerName: "King Cab Auto Service",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		cardIssued: "Visa Card",
		cardType: "Physical Card",
		issuerId: "MT435783",
		issuerName: "King Cab Auto Service",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		cardIssued: "Visa Card",
		cardType: "Physical Card",
		issuerId: "MT435783",
		issuerName: "King Cab Auto Service",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		cardIssued: "Visa Card",
		cardType: "Physical Card",
		issuerId: "MT435783",
		issuerName: "King Cab Auto Service",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		cardIssued: "Visa Card",
		cardType: "Physical Card",
		issuerId: "MT435783",
		issuerName: "King Cab Auto Service",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		cardIssued: "Visa Card",
		cardType: "Physical Card",
		issuerId: "MT435783",
		issuerName: "King Cab Auto Service",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		cardIssued: "Visa Card",
		cardType: "Physical Card",
		issuerId: "MT435783",
		issuerName: "King Cab Auto Service",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		cardIssued: "Visa Card",
		cardType: "Physical Card",
		issuerId: "MT435783",
		issuerName: "King Cab Auto Service",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		walletId: "VT27492294807",
		customerId: "CUS8710878744",
		name: "Osinachukwu Blessed-man",
		cardIssued: "Visa Card",
		cardType: "Physical Card",
		issuerId: "MT435783",
		issuerName: "King Cab Auto Service",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
];
