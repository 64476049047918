import { ReactComponent as BuildingIcon } from "assets/images/icons/buildings.svg";
import Card from "utils/AtmCard/AtmCard";
import { toCurrency } from "utils/helpers";

function DetailSection({ res }) {
	return (
		<div className="row ">
			<div className="col-lg-6">
				<div className="col-md-10 info__section">
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Card ID
						</p>
						<p className="value justify-content-end">
							{res?.cardId}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Card Type
						</p>
						<p className="value justify-content-end">
							{res?.cardType}
						</p>
					</section>

					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Spending Limit
						</p>
						<p className="value justify-content-end">
							{res?.currency} {toCurrency(res?.limit)}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Currency
						</p>
						<p className="value justify-content-end">
							{res?.currency}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Date Issued
						</p>
						<p className="value justify-content-end">
							{res?.createdAt}
						</p>
					</section>
				</div>
			</div>
			<div className="col-lg-3">
				<Card
					name="Jemery"
					number="2334 3332 4322 3342"
					bank="Debit"
					date="02/23"
				/>
			</div>
			<div className="col-lg-3"></div>
		</div>
	);
}

export default DetailSection;
