import $ from "jquery";
import { Link } from "react-router-dom";
import { ReactComponent as SmallLogo } from "assets/images/icons/logo.svg";
import { ReactComponent as Logo } from "assets/images/icons/logofull.svg";
import { ReactComponent as Logout } from "assets/images/icons/logout.svg";
import { activeRoute } from "utils/helpers";
import "./Sidebar.scss";

const SideBarRoute = ({ pathname, Routes, full, history }) => {
  const toggleFullSize = () => {
    $(".main").toggleClass("active");
    $(".sidebar").toggleClass("active");
    $(".small-sidebar").toggleClass("active");
    $(".navbar-toggler").toggleClass("collapsed");
  };

  return (
    <>
      <div
        className="sidebar__header"
        onClick={() => history.push("/dashboard/payments")}
      >
        {full ? <Logo /> : <SmallLogo width={20} height={20} />}
      </div>

      <button
        type="button"
        className="navbar-toggler w-100 transition--ease collapsed"
        onClick={toggleFullSize}
      >
        <div>
          <span className="toggler-icon top-bar" />
          <span className="toggler-icon middle-bar" />
          <span className="toggler-icon bottom-bar" />
        </div>
      </button>

      <ul className="list-unstyled" id="sidebar__nav">
        {Routes.map(
          (route) =>
            !route.hide && (
              <li className="sidebar__nav__item" key={route?.path}>
                <Link
                  to={route.secPath || route.path}
                  className="sidebar__nav__item__link"
                  data-active={activeRoute(
                    route.path,
                    pathname,
                    route.tabs,
                    true
                  )}
                >
                  {route.icon && (
                    <route.icon className={`${full ? "me-4" : ""}`} />
                  )}
                  {full && route.name}
                </Link>
              </li>
            )
        )}
        <li className="sidebar__nav__item sidebar__footer">
          <div className="sidebar__nav__item__link">
            <Link to="/login">
              <Logout className="primary" />
              {full && <span className="ms-4">Logout</span>}
            </Link>
          </div>
        </li>
      </ul>
    </>
  );
};

export default SideBarRoute;
