export const DEFAULT = "DEFAULT";

// LAYOUT CONSTANTS

export const AUTH = "AUTH_LAYOUT";
export const DASHBOARD = "DASHBOARD_LAYOUT";
export const ONBOARDING = "ONBOARDING_LAYOUT";

// MODAL CONSTANTS

export const SHORT = "SHORT";
export const NORMAL = "NORMAL";
export const PRIMARY = "PRIMARY";
export const HEADLESS = "HEADLESS";
export const SECONDARY = "SECONDARY";
export const MID_HEIGHT = "MID_HEIGHT";
export const TRANSFER_CENTER = "TRANSFER_CENTER";

// MODAL TYPE CONSTANTS

export const EDIT = "EDIT";
export const CREATE = "CREATE";

// INPUT CONSTANTS

export const SMALL = "SMALL";
export const LARGE = "LARGE";
export const MEDIUM = "MEDIUM";

// STATUS CONSTANTS

export const PAID = "PAID";
export const DRAFT = "DRAFT";
export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";
export const PENDING = "PENDING";
export const SUCCESS = "SUCCESS";
export const SETTLED = "SETTLED";
export const AWAITING = "AWAITING";
export const RESOLVED = "RESOLVED";
export const ESCALATED = "ESCALATED";

// FORGOT PASSWORD CONSTANTS

export const RESET = "RESET";
export const FORGOT = "FORGOT";

// SIGNUP CONSTANTS

export const BIODATA = "BIODATA";
export const INITIAL = "INITIAL";
export const VERIFICATION = "VERIFICATION";
export const VERIFICATION_PROMPT = "VERIFICATION_PROMPT";
export const VERIFICATION_CONFIRMATION = "VERIFICATION_CONFIRMATION";

// ONBOARDING CONSTANTS
export const BUSINESS_INFO = "BUSINESS_INFO";
export const PHONE_NO_AUTH = "PHONE_NO_AUTH";

// PAYMENT CONSTANTS

export const CARD = "CARD";
export const FROM = "from";
export const QR_CODE = "QR CODE";
export const NEW_CUSTOMER = "New Customer";

// STEP CONSTANTS

export const STEP_1 = 1;
export const STEP_2 = 2;
export const STEP_3 = 3;
export const STEP_4 = 4;
export const STEP_5 = 5;
export const STEP_6 = 6;

// TAB CONSTANTS

export const GRAY = "GRAY";
export const UNDERLINED = "UNDERLINED";

// TRANSACTION CONSTANTS

export const CREDIT = "CREDIT";
export const BANK_TRANSFER = "BANK-TRANSFER";
export const TRANSFER_METHOD = "TRANSFER_METHOD";

// CARD CONSTANTS

export const VISA = "VISA";
export const VERVE = "VERVE";
export const MASTERCARD = "MASTERCARD";

// CUSTOMER TYPE CONSTANTS

export const PERSONAL = "PERSONAL";

// VERIFICATION CONSTANTS

export const VERIFIED = "VERIFIED";

export const UNAUTHENTICATED = "UNAUTHENTICATED";

export const responseStatus = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  EMPTY: "EMPTY",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  SUCCESSFUL: "success",
  PENDING_AUTH: "PENDING_AUTH",
  INVALID: "INVALID",
};

export const TOKEN_KEY = "payment_pro_token";
export const PAGE_INFO = "payment_pro_page_info";
export const CURRENT_FILTERS = "payment_pro_current_filters";

// TERMINAL CONSTANTS

export const ASSIGNED = "ASSIGNED";

// POSITION CONSTANTS

export const CENTER = "CENTER";

// SPLIT PAYMENT CONSTANTS

export const CREATE_SUB = "CREATE_SUB";
export const CREATE_SPLIT = "CREATE_SPLIT";

// FILTER TYPES
export const FILTER_A = "filter-a";
export const FILTER_B = "filter-b";
export const FILTER_C = "filter-c";
export const FILTER_D = "filter-d";
export const FILTER_E = "filter-e";
export const FILTER_F = "filter-f";
export const FILTER_G = "filter-g";
export const FILTER_H = "filter-h";
export const FILTER_I = "filter-i";

export const DATE_FILTERS = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  CUSTOM: "Custom",
};

export const defaultDateFilters = { from: undefined, to: undefined };
