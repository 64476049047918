import { Tab } from "utils/NavTabs/NavTabs";
import { UNDERLINED } from "components/constants";
import { useHistory } from "react-router-dom";

const SectionNav = ({
	routeName,
	tabs,
	navTabChildren,
	navCls,
	navTabCls,
	tabType = UNDERLINED,
	upperDiv,
	cls,
}) => {
	const path = window.location.pathname;
	const history = useHistory();
	return (
		<div
			className={`header px-0 flex-wrap ${cls} border-0 position-relative`}
		>
			{routeName && (
				<div className="w-100 d-flex justify-content-between align-items-start flex-wrap">
					<div className="text--lg fw-bold">{routeName}</div>
				</div>
			)}
			
			{upperDiv && <div className="w-100">{upperDiv}</div>}
			{tabs && (
				<div
					className={`flex__between w-100${
						tabType === UNDERLINED ? " underlined-tabs" : ""
					} ${navTabCls}`}
				>
					<div className={`nav-tabs-container ${navCls}`}>
						<ul className="nav nav-tabs overflow-x">
							{tabs?.map(({ label, route }, i) => (
								<Tab
									id={`route-${i}`}
									active={
										path.startsWith(route) ? "active" : ""
									}
									tabName={label}
									key={`route-${i}`}
									handleClick={() => history.push(route)}
									type={tabType}
								/>
							))}
						</ul>
					</div>

					<div className={!navTabCls ? "ps-4" : ""}>
						{navTabChildren}
					</div>
				</div>
			)}
		</div>
	);
};

export default SectionNav;
