import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function StatDisplay({
	cls,
	displayCls,
	id,
	linkText,
	linkAction,
	LinkIcon,
	title,
	TitleIcon,
	statAction,
	tooltipPlacement = "right",
	tooltipText,
	value,
	PrefixTitleIcon,
}) {
	return (
		<div className={cls} id={id}>
			<div
				className={`section--stat-display ${displayCls}`}
				onClick={statAction}
			>
				<div>
					<h5 className="subHeading heading-3 flex__between flex-wrap pb-1">
						<span className="d-flex flex-wrap">
							<span className="text-uppercase">
								{PrefixTitleIcon && (
									<PrefixTitleIcon className="ms-2 pointer" />
								)}{" "}
								{title}
							</span>
							{TitleIcon && (
								<OverlayTrigger
									key={title}
									placement={tooltipPlacement}
									trigger={["hover", "focus"]}
									overlay={
										!tooltipText ? (
											<></>
										) : (
											<Tooltip
												id={`tooltip-${tooltipPlacement}`}
											>
												{tooltipText}
											</Tooltip>
										)
									}
								>
									<span>
										<TitleIcon className="ms-2 pointer" />
									</span>
								</OverlayTrigger>
							)}
						</span>
						{linkText && linkAction && (
							<span
								className="link d-flex flex-wrap pointer"
								onClick={linkAction}
							>
								<span className="gradient">{linkText}</span>
								{LinkIcon && (
									<LinkIcon className="ms-2 stroke-gradient" />
								)}
							</span>
						)}
					</h5>
					<div className="text--lg text-break">{value}</div>
				</div>
			</div>
		</div>
	);
}