import { useState } from "react";
import Table from "utils/Table/Table";
import UsersFilters from "../../common/UsersFilters";
import {
	userFiltersDefaultValues,
	sessionsTableData,
	sessionTableHeadings,
} from "../../helpers/data";

const Sessions = () => {
	const [formValues, setFormValues] = useState({
		...userFiltersDefaultValues,
	});

	const handleChange = ({ target: { name, value } }) =>
		handleSelect(name, value);

	const handleSelect = (name, value) => {
		console.log({ name, value });
		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	const handleSubmit = () => setFormValues({ ...userFiltersDefaultValues });

	const getActivityData = () => {
		return sessionsTableData;
	};

	const handleRowClick = (_, rowValues) => {
		// history.push(`/providers/${rowValues.transactionID}/overview`);
	};
	return (
		<>
			<div className="position-relative">
				<UsersFilters
					formValues={formValues}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					handleSelect={handleSelect}
				/>
				<Table
					data={getActivityData()}
					headings={sessionTableHeadings}
					rowClick={handleRowClick}
				/>
			</div>
		</>
	);
};

export default Sessions;
