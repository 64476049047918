export const paymentDashboardAnalyticsData = [
	{
		title: "TOTAL AMOUNT PROCESSED",
		amount: "₦ 839,394,394",
		increase: "+6.48%",
	},
	{
		title: "Value of Charges",
		amount: "₦ 839,394,394",
		increase: "+6.48%",
	},
	{
		title: "Total Value Outflow",
		amount: "₦ 839,394,394",
		increase: "+6.48%",
	},
	{
		title: "Total Value  Inflow",
		amount: "₦ 839,394,394",
		increase: "+6.48%",
	},
];

export const aggregatorDashboardAnalyticsData = [
	{
		title: "All Registered AGGREGATORS",
		amount: "839,394,394",
		increase: "+6.48%",
	},
	{
		title: "Active Aggregators",
		amount: "839,394,394",
		increase: "+6.48%",
	},
	{
		title: "Blocked Aggregators",
		amount: "839,394,394",
		increase: "+6.48%",
	},
	{
		title: "Total Customers Added",
		amount: "839,394,394",
		increase: "+6.48%",
	},
];

export const infoStatsDateOptions = [
	"This Week (12 Mar, 2022 - 18 Mar, 2022)",
	"Last Week (02 Mar, 2022 - 11 Mar, 2022)",
	"2 Weeks Ago (22 Feb, 2022 - 01 Mar, 2022)",
];

const chartDefaults = {
	textStyle: {
		color: "#B2B6BA",
		fontFamily: "gilroy-regular",
		fontSize: 11,
		fontWeight: 500,
		overflow: "truncate",
		lineOverflow: "truncate",
	},
	tooltip: {
		trigger: "axis",
		axisPointer: {
			z: 10,
		},
	},
};

export const totalTransactionsChartOptions = (chartData) => {
	return {
		...chartDefaults,
		color: [...chartData.map((data) => data.color)],
		grid: {
			bottom: 0,
			containLabel: true,
			left: 0,
			right: 0,
			top: 0,
		},
		series: [
			{
				name: "Total Transactions",
				type: "pie",
				radius: ["50%", "80%"],
				label: {
					show: false,
					position: "center",
				},
				selectedMode: "multiple",
				emphasis: {
					label: {
						show: true,
						fontSize: "25",
						fontWeight: "bold",
					},
				},
				labelLine: {
					show: false,
				},
				data: chartData,
			},
		],
		tooltip: {
			trigger: "item",
		},
	};
};

export const transactionDistributionData = [
	{
		name: "Payment Link",
		value: "₦ 839,394,394",
	},
	{
		name: "Payment PAGE",
		value: "₦ 839,394,394",
	},
	{
		name: "Payment",
		value: "₦ 839,394,394",
	},
	{
		name: "SUBSCRIPTION",
		value: "₦ 839,394,394",
	},
	{
		name: "INvoice",
		value: "₦ 839,394,394",
	},
	{
		name: "Payouts",
		value: "₦ 839,394,394",
	},
	{
		name: "QR CODE",
		value: "₦ 839,394,394",
	},
	{
		name: "TERMINAL",
		value: "₦ 839,394,394",
	},
];

export const transactionDistributionPercent = [
	{
		name: "Payment Link",
		value: 20,
	},
	{
		name: "Payment Pge",
		value: "₦ 839,394,394",
	},
	{
		name: "Subscription",
		value: "₦ 839,394,394",
	},
	{
		name: "Invoice",
		value: "₦ 839,394,394",
	},
	{
		name: "Payout",
		value: "₦ 839,394,394",
	},
	{
		name: "Terminal",
		value: "₦ 839,394,394",
	},
	{
		name: "QR Code",
		value: "₦ 839,394,394",
	},
	{
		name: "Payments",
		value: "₦ 839,394,394",
	},
];

export const highRiskTransOption = {
	...chartDefaults,
	color: "#F1AD87",
	grid: {
		bottom: 0,
		containLabel: true,
		left: 0,
		right: 0,
		top: 0,
	},
	yAxis: {
		axisLabel: {
			show: true,
		},
		axisTick: {
			show: false,
		},
		axisLine: {
			show: false,
		},
		axisPointer: {
			show: true,
			type: "none",
			color: "#E2E4E5",
		},
		type: "category",
		data: [
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
		],
	},
	xAxis: {
		axisLabel: {
			show: true,
		},
		axisTick: {
			show: false,
		},
		splitLine: {
			show: true,
			lineStyle: {
				type: "dashed",
			},
		},
		type: "value",
	},

	series: [
		{
			name: "X",
			type: "bar",
			data: [100, 50, 150, 200, 250, 300, 550],
			label: {
				show: true,
				position: "right",
			},
		},
	],
	legend: {
		show: false,
	},
};

export const revenueStatsOptions = {
	xAxis: {
		type: "category",
		data: [
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
		],
	},
	yAxis: {
		type: "value",
		axisLabel: {
			formatter: "{value} M",
		},
		data: [2, 4, 6, 8, 10, 12, 14, 16, 18],
	},
	series: [
		{
			data: [2, 6, 7, 8, 11, 16, 18],
			type: "bar",
			name: "M",
			barWidth: 39,
			stack: "total",
			itemStyle: {
				color: "#2CC7A2",
			},
		},
	],
};
