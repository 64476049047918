import WatcherRegistrationCard from "components/Dashboard/Watcher/utils/Cards/WatcherRegistrationCard/WatcherRegistrationCard";
import { watcherRegistrationData } from "components/Dashboard/Watcher/utils/data";
import "./WatcherRegistration.scss";

const WatcherRegistrations = () => {
	return (
		<div className="watcher-reg">
			<div className="container-fluid">
				<div className="row">
					<div className="col-3 p-2">
						<div className="col-inner p-4">
							<div className="flex__between">
								<div className="title">
									<h5>New Aggregator Registration</h5>
								</div>
								<div className="tag">
									<h5>235</h5>
								</div>
							</div>
							<div className="col-inner-body pt-4">
								{watcherRegistrationData.map((data, i) => (
									<WatcherRegistrationCard
										key={i}
										watcher={data}
									/>
								))}
							</div>
						</div>
					</div>

					<div className="col-3 p-2">
						<div className="col-inner p-4">
							<div className="flex__between">
								<div className="title">
									<h5>New Merchant Registration</h5>
								</div>
								<div className="tag">
									<h5>235</h5>
								</div>
							</div>
							<div className="col-inner-body pt-4">
								{watcherRegistrationData.map((data, i) => (
									<WatcherRegistrationCard
										key={i}
										watcher={data}
									/>
								))}
							</div>
						</div>
					</div>

					<div className="col-3 p-2">
						<div className="col-inner p-4">
							<div className="flex__between">
								<div className="title">
									<h5>Logged in Aggregators</h5>
								</div>
								<div className="tag">
									<h5>235</h5>
								</div>
							</div>
							<div className="col-inner-body pt-4">
								{watcherRegistrationData.map((data, i) => (
									<WatcherRegistrationCard
										key={i}
										watcher={data}
									/>
								))}
							</div>
						</div>
					</div>

					<div className="col-3 p-2">
						<div className="col-inner p-4">
							<div className="flex__between">
								<div className="title">
									<h5>Logged in Merchants</h5>
								</div>
								<div className="tag">
									<h5>235</h5>
								</div>
							</div>
							<div className="col-inner-body pt-4">
								{watcherRegistrationData.map((data, i) => (
									<WatcherRegistrationCard
										key={i}
										watcher={data}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WatcherRegistrations;
