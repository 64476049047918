import ChartGraph from "utils/Charts/ChartGraph/ChartGraph";

const option = {
	xAxis: {
		type: "category",
		grid: {
			bottom: 0,
			containLabel: true,
			left: 0,
			right: 0,
			top: 0,
		},
		data: [
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
		],
		axisLabel: {
			show: true,
		},
		axisTick: {
			show: false,
		},
		splitLine: {
			show: false,
		},
		axisLine: {
			show: false,
		},
	},
	yAxis: {
		type: "value",
		axisLabel: {
			formatter: "{value} K",
		},
		axisTick: {
			show: false,
		},
		axisLine: {
			show: false,
		},
		axisPointer: {
			show: true,
			type: "none",
			color: "#E2E4E5",
		},
		data: [2, 4, 6, 8, 10, 12, 14, 16, 18],
	},
	series: [
		{
			data: [2, 6, 7, 8, 11, 16, 18],
			type: "bar",
			name: "M",
			barWidth: 39,
			stack: "total",
			color: {
				type: "linear",
				colorStops: [
					{
						offset: 0,
						color: "rgba(123, 223, 187, 1)",
					},
					{
						offset: 1,
						color: "rgba(246, 204, 204, 1)",
					},
				],
			},
		},
	],
};
const NewRegistrations = () => {
	return (
		<div className="pt-1">
			<ChartGraph
				option={option}
				styles={{
					height: "361px",
				}}
			/>
		</div>
	);
};

export default NewRegistrations;
