import { Link, useHistory } from "react-router-dom";
import "assets/styles/scss/auth.scss";
import CustomPassword from "utils/CustomPassword/CustomPassword";

const ResetPassword = () => {
  const history = useHistory();
  return (
    <div className="auth-container flex__center">
      <div className="auth-card col-lg-3 col-md-5 col-sm-5 col-11 shadow-lg flex__between">
        <div className="text-center">
          <h3 className="fw-bold">Create New Password</h3>
          <h5>
            Your password is private and should not be <br /> compromised in any
            way
          </h5>
        </div>
        <form action="">
          <CustomPassword
            label="New Password"
            placeholder="Your access Password"
          />
          <CustomPassword
            label="Confirm Password"
            placeholder="Your access Password"
          />
          <div className="d-grid mt-4">
            <button
              className="btn btn--primary"
              type="button"
              onClick={() => history.push("/")}
            >
              Reset Password
            </button>
          </div>
        </form>
        <div>
          <Link to={"/"}>
            Need Help? <b>Contact Support</b>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
