import { useEffect, useState } from "react";
import { ReactComponent as CalenderIcon } from "assets/images/icons/calendar.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import DateRangePicker from "utils/CustomDatePicker/DateRangePicker";
import { getDateRange, timeRangeFormat } from "utils/helpers";
import { DATE_FILTERS } from "components/constants";

const { DAILY, WEEKLY, MONTHLY, CUSTOM } = DATE_FILTERS;

const options = [DAILY, WEEKLY, MONTHLY];

const DateFilters = ({ filters, handleFilters, clearFilters }) => {
  const [showDate, setShowDate] = useState(false);
  const [date, setDate] = useState({
    from: new Date(),
    to: null,
  });
  const { dateType } = filters || {};

  useEffect(() => {
    const { from, to } = filters || {};
    from !== date.from && handleChange("from", from);
    to !== date.to && handleChange("to", to);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const toggleDate = () => setShowDate((prev) => !prev);

  const closeDate = () => setShowDate(false);

  const validateDate = () => {
    const { from, to } = date || {};
    if (from && to) {
      if (from.getTime() <= to.getTime()) handleSubmit(CUSTOM);
      else handleChange("from", to);
    }
  };

  const handleChange = (name, value) => {
    value =
      name === "from" ? timeRangeFormat(value, true) : timeRangeFormat(value);

    setDate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clear = () => {
    clearFilters && clearFilters(true);
    closeDate();
  };

  const handleSubmit = (type) => {
    closeDate();
    if (dateType !== type || dateType === CUSTOM) {
      const data = getDateRange(type, date);
      handleFilters && handleFilters({ dateType: type, data });
    }
  };
  return (
    <div className="nav-tabs-container overflow-visible mb-0">
      <ul className="nav nav-tabs rel">
        {options?.map((option, i) => (
          <li
            className={`nav-link bordered-nav-link  fw-bold ${
              option === dateType ? "active" : ""
            }`}
            key={`tab${i}`}
            onClick={() => handleSubmit(option)}
          >
            {option}
          </li>
        ))}

        <li
          className={`nav-link bordered-nav-link text-center  fw-bold rel ${
            dateType === CUSTOM ? "active" : ""
          }`}
          onClick={toggleDate}
        >
          <CalenderIcon className="me-2" /> Custom
        </li>

        {dateType && (
          <li
            className={`nav-link bordered-nav-link text-center fw-bold`}
            onClick={clear}
          >
            <CloseIcon className="danger me-2" /> Clear
          </li>
        )}

        {showDate && (
          <DateRangePicker
            startDate={date?.from}
            endDate={date?.to}
            maxDate={new Date()}
            handleChange={handleChange}
            onSubmit={validateDate}
          />
        )}
      </ul>
    </div>
  );
};

export default DateFilters;
