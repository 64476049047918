import React from "react";
import { CustomToggleSwitchWrapper } from "./styled.customtoggleswitch";

const CustomToggleSwitch = ({ handleChange, name, title, isChecked }) => {
  return (
    <CustomToggleSwitchWrapper className="d-flex my-4 gap-4 align-items-start align-items-lg-center toggle-switch">
      <label>
        <input
          type="checkbox"
          onChange={handleChange}
          name={name}
          checked={isChecked}
        />
        <span />
      </label>
      <h5 className="mb-0 switch-title">{title}</h5>
    </CustomToggleSwitchWrapper>
  );
};

export default CustomToggleSwitch;
