import ChartGraph from "utils/Charts/ChartGraph/ChartGraph";
import { virtualAccountDistributionOptions } from "./data";

const VirtualAccountDistribution = () => {
	return (
		<ChartGraph
			option={virtualAccountDistributionOptions}
			styles={{
				height: "255px",
			}}
		/>
	);
};

export default VirtualAccountDistribution;
