import { ReactComponent as ChartRingIcon } from "assets/images/icons/chart-ring.svg";
import "./chartStatus.scss";

const ChartStatus = ({ value, name, cls, icon }) => {
	return (
		<div className="row my-3">
			<div className="col-10">
				<ChartRingIcon className={`me-3 mb-1 ${cls}`} />
				<span className="card-icon">{icon}</span>
				<span className="text">{name}</span>
			</div>
			<div className="col-2 text">{value}</div>
		</div>
	);
};

export default ChartStatus;
