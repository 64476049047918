import { createElement } from "react";

const TableCell = ({ cellValues, tag }) =>
	createElement(
		tag,
		{ className: `text-nowrap${tag === "th" ? " text-capitalize" : ""}` },
		cellValues
	);

export default TableCell;
