import Modal from "utils/Modal/Modal";
import { permissionResponse } from "../../helpers/data";
import CustomToggleSwitch from "utils/CustomToggleSwitch/CustomToggleSwitch";

const PermissionModal = ({ res = permissionResponse, toggle, IDTabName }) => {
	return (
		<Modal size="md" toggle={toggle} title={IDTabName}>
			<div className="px-4">
				{permissionResponse.map((data) => (
					<div className="row mb-2 bg--faint-secondary">
						<div className="col-4 border-end">
							<div className="px-3 py-0 rounded flex__start">
								<span>
									<CustomToggleSwitch />
								</span>
								<span className="fw-bold">{data.name}</span>
							</div>
						</div>
						<div className="col-8 flex__start">
							<div className="px-3 rounded d-flex">
								<div class="form-check me-4">
									<input
										class="form-check-input mt-0"
										type="checkbox"
										value=""
									/>
									<label
										class="form-check-label"
										for="flexCheckDefault"
									>
										View
									</label>
								</div>
								<div class="form-check me-4">
									<input
										class="form-check-input mt-0"
										type="checkbox"
										value=""
									/>
									<label
										class="form-check-label"
										for="flexCheckDefault"
									>
										Edit
									</label>
								</div>
								<div class="form-check me-4">
									<input
										class="form-check-input mt-0"
										type="checkbox"
										value=""
									/>
									<label
										class="form-check-label"
										for="flexCheckDefault"
									>
										Delete
									</label>
								</div>
								<div class="form-check me-4">
									<input
										class="form-check-input mt-0"
										type="checkbox"
										value=""
									/>
									<label
										class="form-check-label"
										for="flexCheckDefault"
									>
										Create
									</label>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</Modal>
	);
};

export default PermissionModal;
