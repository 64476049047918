import React from "react";
import { ReactComponent as ChipIcon } from "assets/images/icons/atm-chip.svg";

function AtmCard({ name, number, bank, date }) {
	return (
		<div className="atm-card">
			<div>
				<p className="text-end">{bank}</p>
			</div>
			<div>
				<ChipIcon className="mb-3" />
				<h2>{number}</h2>
			</div>
			<div className="flex__start">
				<div className="me-5">
					<p>
						<span className="fs-6">Card Holder Name</span> <br />
						{name}
					</p>
				</div>
				<div>
					<p>
						<span className="fs-6">Expiry Date</span> <br />
						{date}
					</p>
				</div>
			</div>
		</div>
	);
}

export default AtmCard;
