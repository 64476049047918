import { Redirect, Switch, Route } from "react-router-dom";
import Routes from "./routes";

const SubRoutes = () => {
	return (
		<Switch>
			{Routes.map(({ path, exact, secPath, component, tabs }) =>
				tabs && tabs?.length > 0 ? (
					tabs?.map(({ path, exact, secPath, component }) => (
						<Route
							key={path}
							path={secPath || path}
							exact={exact}
							component={component}
						/>
					))
				) : (
					<Route
						key={path}
						path={secPath || path}
						exact={exact}
						component={component}
					/>
				)
			)}

			<Route
				exact
				path="*"
				component={() => <Redirect to="/watcher/registrations" />}
			/>
		</Switch>
	);
};

export default SubRoutes;
