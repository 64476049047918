import { useState } from "react";
import { toCurrency } from "utils/helpers";
import { PENDING, SUCCESS } from "utils/helpers/constants";
import StatusPill from "utils/pills/StatusPill/StatusPill";
import Table from "utils/Table/Table";
import ProviderServiceModal from "../../common/modals/ProviderServiceModal";
import ProviderFilters from "../../common/ProviderFilters";
import {
	providerFiltersDefaultValues,
	providerServicesTableData,
	providerServicesTableHeadings,
} from "../../helpers/data";

const ProviderServicesTable = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [currentRowValues, setCurrentRowValues] = useState({});
	const [formValues, setFormValues] = useState({
		...providerFiltersDefaultValues,
	});

	const toggleModal = () => setIsOpen((prev) => !prev);

	const handleChange = ({ target: { name, value } }) =>
		handleSelect(name, value);

	const handleSelect = (name, value) => {
		console.log({ name, value });
		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	const handleSubmit = () =>
		setFormValues({ ...providerFiltersDefaultValues });

	const getWalletData = () =>
		providerServicesTableData?.map((wallet) => ({
			id: wallet?.id,
			email: wallet?.email,
			method: wallet?.method,
			amount: toCurrency(wallet.amount),
			status: (
				<StatusPill
					status={wallet?.status}
					success={SUCCESS}
					warning={PENDING}
				/>
			),
			createdAt: wallet?.createdAt,
		}));

	const handleRowClick = (_, rowValues) => {
		const providersData = providerServicesTableData?.find(
			(transaction) => transaction?.id === rowValues?.id
		);
		setCurrentRowValues({
			...rowValues,
			...providersData,
		});
		toggleModal();
	};
	return (
		<>
			<div className="position-relative">
				<ProviderFilters
					formValues={formValues}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					handleSelect={handleSelect}
				/>
				<Table
					data={getWalletData()}
					headings={providerServicesTableHeadings}
					rowClick={handleRowClick}
				/>
				{isOpen && (
					<ProviderServiceModal
						toggle={toggleModal}
						payment={currentRowValues}
						IDTabName={`Transaction Information`}
					/>
				)}
			</div>
		</>
	);
};

export default ProviderServicesTable;
