export const topMerchantsData = [
	{
		title: "FastPay Financial Services",
		amount: "₦ 39,234,394",
		progress: 90,
	},
	{ title: "CBI", amount: "₦ 39,234,394", progress: 70 },
	{ title: "Teller-Pay", amount: "₦ 39,234,394", progress: 10 },
	{ title: "Bit Pay", amount: "₦ 39,234,394", progress: 50 },
	{ title: "Strolys", amount: "₦ 39,234,394", progress: 20 },
];

export const financialReportOption = {
	xAxis: {
		type: "category",
		grid: {
			bottom: 0,
			containLabel: true,
			left: 0,
			right: 0,
			top: 0,
		},
		data: [
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
		],
		axisLabel: {
			show: true,
		},
		axisTick: {
			show: false,
		},
		splitLine: {
			show: false,
		},
		axisLine: {
			show: false,
		},
	},
	yAxis: {
		type: "value",
		axisLabel: {
			formatter: "{value} M",
		},
		axisTick: {
			show: false,
		},
		axisLine: {
			show: false,
		},
		axisPointer: {
			show: true,
			type: "none",
			color: "#E2E4E5",
		},
		data: [2, 4, 6, 8, 10, 12, 14, 16, 18],
	},
	series: [
		{
			data: [2, 6, 7, 8, 11, 16, 18],
			type: "bar",
			name: "M",
			barWidth: 39,
			stack: "total",
			color: {
				type: "linear",
				colorStops: [
					{
						offset: 0,
						color: "rgba(123, 223, 187, 1)",
					},
					{
						offset: 1,
						color: "rgba(246, 204, 204, 1)",
					},
				],
			},
		},
	],
};

export const mostActiveDaysOption = {
	tooltip: {
		trigger: "axis",
		axisPointer: {
			type: "shadow",
			show: false,
		},
	},
	grid: {
		left: "3%",
		right: "4%",
		bottom: "3%",
		containLabel: true,
	},
	xAxis: [
		{
			type: "category",
			data: ["M", "T", "W", "T", "F", "S", "S"],
			axisTick: {
				alignWithLabel: true,
				show: false,
			},
			axisLabel: {
				show: true,
			},

			splitLine: {
				show: false,
			},
		},
	],
	yAxis: [
		{
			type: "value",
			axisLabel: {
				show: true,
			},
			axisTick: {
				show: false,
			},
			axisLine: {
				show: false,
			},
			axisPointer: {
				show: true,
				type: "none",
				color: "#E2E4E5",
			},
		},
	],
	series: [
		{
			name: "Direct",
			type: "bar",
			barWidth: "40%",
			data: [10, 52, 200, 334, 390, 330, 220],
			color: {
				type: "linear",
				colorStops: [
					{
						offset: 0,
						color: "#125A9F",
					},
					{
						offset: 1,
						color: "#509FEF",
					},
				],
			},
		},
	],
};

export const newRegistrationOption = {
	xAxis: {
		type: "category",
		grid: {
			bottom: 0,
			containLabel: true,
			left: 0,
			right: 0,
			top: 0,
		},
		data: [
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
		],
		axisLabel: {
			show: true,
		},
		axisTick: {
			show: false,
		},
		splitLine: {
			show: false,
		},
		axisLine: {
			show: false,
		},
	},
	yAxis: {
		type: "value",
		axisLabel: {
			formatter: "{value} K",
		},
		axisTick: {
			show: false,
		},
		axisLine: {
			show: false,
		},
		axisPointer: {
			show: true,
			type: "none",
			color: "#E2E4E5",
		},
		data: [2, 4, 6, 8, 10, 12, 14, 16, 18],
	},
	series: [
		{
			data: [2, 6, 7, 8, 11, 16, 18],
			type: "bar",
			name: "M",
			barWidth: 39,
			stack: "total",
			color: {
				type: "linear",
				colorStops: [
					{
						offset: 0,
						color: "rgba(123, 223, 187, 1)",
					},
					{
						offset: 1,
						color: "rgba(246, 204, 204, 1)",
					},
				],
			},
		},
	],
};
