import { ProgressBarWrapper } from "./styled.progressBar";

const ProgressBar = (props) => {
	const { bgcolor, completed, height, width, borderRadius, text } = props;

	return (
		<ProgressBarWrapper
			height={height}
			width={width}
			borderRadius={borderRadius}
			bgcolor={bgcolor}
			completed={completed}
		>
			<div className="filler">
				<span className="label">{text}</span>
				<span className="value">{completed}</span>
			</div>
		</ProgressBarWrapper>
	);
};

export default ProgressBar;
