import StatDisplay from "utils/StatDisplay/StatDisplay";
import { ReactComponent as InfoCircleIcon } from "assets/images/icons/info-circle.svg";
import { ReactComponent as Money } from "assets/images/icons/money.svg";
import { toCurrency } from "utils/helpers";
import ProviderOverviewBarChart from "./Charts/ProviderOverviewBarChart/ProviderOverviewBarChart";
import TransactionStatus from "./Charts/TransactionStatus/TransactionStatus";

function ProviderOverviewChart() {
	return (
		<div>
			<div className="row">
				<div className="col-lg-3 col-md-6">
					<StatDisplay
						cls="pe-2 c"
						title="TOTAL TRANSACTIONS VALUE"
						PrefixTitleIcon={Money}
						TitleIcon={InfoCircleIcon}
						tooltipText="TOTAL TRANSACTIONS VALUE"
						value={`₦ ${toCurrency(784294.94)}`}
					/>
				</div>
				<div className="col-lg-3 col-md-6">
					<StatDisplay
						cls="ps-2"
						PrefixTitleIcon={Money}
						title="NUMBER OF TRANSACTIONS"
						TitleIcon={InfoCircleIcon}
						tooltipText="NUMBER OF TRANSACTIONS"
						value={2848}
					/>
				</div>

				<div className="col-lg-3 col-md-6">
					<StatDisplay
						cls="pe-2 c"
						title="TOTAL Value of Charges"
						PrefixTitleIcon={Money}
						TitleIcon={InfoCircleIcon}
						tooltipText="TOTAL Value of Charges"
						value={`₦ ${toCurrency(784294.94)}`}
					/>
				</div>
			</div>
			<div className="row mt-5">
				<div className="col-lg-9">
					<ProviderOverviewBarChart />
				</div>
				<div className="col-lg-3">
					<TransactionStatus/>
				</div>
			</div>
		</div>
	);
}

export default ProviderOverviewChart;
