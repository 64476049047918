import { ReactComponent as BuildingIcon } from "assets/images/icons/buildings.svg";
import AtmCard from "utils/AtmCard/AtmCard";

function DetailSection({ res }) {
	return (
		<div className="row ">
			<div className="col-lg-6">
				<div className="col-md-10 info__section">
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Card ID
						</p>
						<p className="value justify-content-end">
							{res?.cardId}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Card Type
						</p>
						<p className="value justify-content-end">
							{res?.cardType}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Card
						</p>
						<p className="value justify-content-end">{res?.card}</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Card Holder
						</p>
						<p className="value justify-content-end">
							{res?.cardHolder}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Currency
						</p>
						<p className="value justify-content-end">
							{res?.currency}
						</p>
					</section>

					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Date Created
						</p>
						<p className="value justify-content-end">
							{res?.createdAt}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Expiry Date
						</p>
						<p className="value justify-content-end">
							{res?.expiredAt}
						</p>
					</section>
				</div>
			</div>
			<div className="col-lg-3">
				<AtmCard
					name="Jemery"
					number="2334 3332 4322 3342"
					bank="Debit"
					date="02/23"
				/>
			</div>
		</div>
	);
}

export default DetailSection;
