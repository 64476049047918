import Routes from "components/RoutesWrapper/routes";
import SideBarRoute from "./SidebarRoute";
import { activeRoute } from "utils/helpers";
import "./Sidebar.scss";

const Sidebar = ({ location, history }) => {
	const { pathname } = location;

	const getIconSidebar = () => {
		return (
			<>
				<SideBarRoute
					Routes={Routes}
					history={history}
					pathname={pathname}
					activeRoute={activeRoute}
				/>
			</>
		);
	};

	return (
		<>
			<div className="sidebar">
				<SideBarRoute
					full
					Routes={Routes}
					history={history}
					pathname={pathname}
					activeRoute={activeRoute}
				/>
			</div>
			<div className="small-sidebar active">{getIconSidebar()}</div>
		</>
	);
};

export default Sidebar;
