import Modal from "utils/Modal/Modal";
import { ReactComponent as BuildingIcon } from "assets/images/icons/buildings.svg";
import { cardResponse } from "../../helpers/data";

const WalletInfoModal = ({ res = cardResponse, toggle, IDTabName }) => {
	return (
		<Modal toggle={toggle} size='xl' title={IDTabName}>
			<div className="row border-bottom ">
				<div className="col-6 px-5 py-3 border-end">
					<div className="info__section">
						<h4 className="fw-bold text-uppercase">
							Transction Details
						</h4>
						<section className="mt-4">
							<p className="key text-uppercase">
								<BuildingIcon /> Card ID
							</p>
							<p className="value justify-content-end">
								{res?.cardId}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Card Type
							</p>
							<p className="value justify-content-end">
								{res?.cardType}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Card
							</p>
							<p className="value justify-content-end">
								{res?.card}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Card Holder
							</p>
							<p className="value justify-content-end">
								{res?.cardHolder}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Currency
							</p>
							<p className="value justify-content-end">
								{res?.currency}
							</p>
						</section>

						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Date Created
							</p>
							<p className="value justify-content-end">
								{res?.createdAt}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Expiry Date
							</p>
							<p className="value justify-content-end">
								{res?.expiredAt}
							</p>
						</section>
					</div>
				</div>
				<div className="col-6 px-5 py-3">
					<div className="info__section">
						<h4 className="fw-bold text-uppercase">
							Wallet Information
						</h4>
						<section className="mt-4">
							<p className="key text-uppercase">
								<BuildingIcon /> Card ID
							</p>
							<p className="value justify-content-end">
								{res?.cardId}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Card Type
							</p>
							<p className="value justify-content-end">
								{res?.cardType}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Card
							</p>
							<p className="value justify-content-end">
								{res?.card}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Card Holder
							</p>
							<p className="value justify-content-end">
								{res?.cardHolder}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Currency
							</p>
							<p className="value justify-content-end">
								{res?.currency}
							</p>
						</section>
					</div>
				</div>
			</div>
			<div className="row ">
				<div className="col-6 px-5 py-4 border-end">
					<div className="info__section">
						<h4 className="fw-bold text-uppercase">
							Recipient Information
						</h4>
						<section className="mt-4">
							<p className="key text-uppercase">
								<BuildingIcon /> Card
							</p>
							<p className="value justify-content-end">
								{res?.card}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Card Holder
							</p>
							<p className="value justify-content-end">
								{res?.cardHolder}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Currency
							</p>
							<p className="value justify-content-end">
								{res?.currency}
							</p>
						</section>

						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Date Created
							</p>
							<p className="value justify-content-end">
								{res?.createdAt}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Expiry Date
							</p>
							<p className="value justify-content-end">
								{res?.expiredAt}
							</p>
						</section>
					</div>
				</div>
				<div className="col-6 px-5 py-3">
					<div className="info__section">
						<h4 className="fw-bold text-uppercase">
							Other Information
						</h4>
						<section className="mt-4">
							<p className="key text-uppercase">
								<BuildingIcon /> Card ID
							</p>
							<p className="value justify-content-end">
								{res?.cardId}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Card Type
							</p>
							<p className="value justify-content-end">
								{res?.cardType}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Card
							</p>
							<p className="value justify-content-end">
								{res?.card}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Card Holder
							</p>
							<p className="value justify-content-end">
								{res?.cardHolder}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Currency
							</p>
							<p className="value justify-content-end">
								{res?.currency}
							</p>
						</section>

						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Date Created
							</p>
							<p className="value justify-content-end">
								{res?.createdAt}
							</p>
						</section>
						<section>
							<p className="key text-uppercase">
								<BuildingIcon /> Expiry Date
							</p>
							<p className="value justify-content-end">
								{res?.expiredAt}
							</p>
						</section>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default WalletInfoModal;
