import { ReactComponent as BuildingIcon } from "assets/images/icons/buildings.svg";
import { ReactComponent as InfoCircleIcon } from "assets/images/icons/info-circle.svg";
import { toCurrency } from "utils/helpers";
import StatDisplay from "utils/StatDisplay/StatDisplay";

function DetailSection({ res }) {
	return (
		<div className="row ">
			<div className="col-lg-6">
				<div className="col-md-10 info__section">
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Account Number
						</p>
						<p className="value justify-content-end">
							{res?.number}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Bank Name
						</p>
						<p className="value justify-content-end">{res?.bank}</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Currency
						</p>
						<p className="value justify-content-end">
							{res?.currency}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Account Owner
						</p>
						<p className="value justify-content-end">{res?.name}</p>
					</section>

					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Date Created
						</p>
						<p className="value justify-content-end">
							{res?.createdAt}
						</p>
					</section>
				</div>
			</div>
			<div className="col-lg-3">
				<StatDisplay
					cls="ps-2"
					title="NUMBER OF TRANSACTIONS"
					TitleIcon={InfoCircleIcon}
					tooltipText="Total Number of transactions processed by all terminals."
					value={71}
				/>
			</div>
			<div className="col-lg-3">
				<StatDisplay
					cls="pe-2 c"
					title="TOTAL TRANSACTIONS PROCESSED"
					TitleIcon={InfoCircleIcon}
					tooltipText="Total Amount of money that was paid into your wallet. This is the sum of all payments plus charges."
					value={`₦ ${toCurrency(784294.94)}`}
				/>
			</div>
		</div>
	);
}

export default DetailSection;
