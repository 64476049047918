// STATUS CONSTANTS

export const PAID = "PAID";
export const DRAFT = "DRAFT";
export const ACTIVE = "ACTIVE";
export const PENDING = "PENDING";
export const SUCCESS = "SUCCESS";
export const SETTLED = "SETTLED";
export const AWAITING = "AWAITING";
export const RESOLVED = "RESOLVED";
export const ESCALATED = "ESCALATED";
