import { Link, useHistory } from "react-router-dom";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import "assets/styles/scss/auth.scss";
const ForgotPassword = () => {
  const history = useHistory();
  return (
    <div className="auth-container flex__center">
      <div className="auth-card col-lg-3 col-md-5 col-sm-5 col-11 shadow-lg flex__between">
        <div className="text-center">
          <h3 className="fw-bold">Forgot Password?</h3>
          <h5>Enter your email addres to reset your Password</h5>
        </div>
        <form action="">
          <CustomInputBox
            label="Email Address"
            placeholder="Enter your email Address"
          />

          <div className="d-grid mt-4 mb-4 pb-4">
            <button
              className="btn btn--primary"
              type="button"
              onClick={() => history.push("/reset-password")}
            >
              Send Reset Link
            </button>
          </div>
        </form>
        <div>
          <Link to={"/"}>
            Need Help? <b>Contact Support</b>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
