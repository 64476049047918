export const providerFiltersDefaultValues = {
	PaymentID: "",
	wallet: false,
	success: false,
	pending: false,
	failed: false,
	dateCreated: null,
};

export const cardResponse = {
	cardId: "WT27492294807",
	cardType: "Virtual",
	card: "Master Card",
	cardHolder: "Jeremy Smith",
	limit: 67783444.87,
	currency: "NGN",
	createdAt: "12 Jan, 2022, 13:54:05 GMT",
	expiredAt: "12 Jan, 2022, 13:54:05 GMT",
};

export const providerTableHeadings = [
	"Provider ID",
	"Provider Name",
	"Email",
	"Phone Number",
	"Status",
	"Date Created",
];

export const providerServicesTableHeadings = [
	"Provider ID",
	"Email",
	"Payment Method",
	"Amount",
	"Status",
	"Date Created",
];

export const providerPricingTableHeadings = [
	"Payment Type",
	"Charging Type",
	"Value",
	"Cap",
	"Action",
];

export const providerIntegrationsTableHeadings = [
	"IP Range",
	"IP End",
	"NUmber of Addresses",
	"Description",
	"Action",
];

export const providerRouteTableHeadings = [
	"Route",
	"Cost",
	"HTTP Method",
	"Priority",
	"Action",
];

export const providerKeysTableHeadings = [
	"Key",
	"Key Type",
	"Created At",
	"Expires At",
	"Action",
];

export const providerWebHookHeadings = [
	"Webhook",
	"Secret Key",
	"Created At",
	"Expires At",
	"Action",
];

export const providerResponse = {
	providerId: "CUS27492294807",
	providerName: "Patricia LTD",
	email: "info@patricia.com",
	phone: "+234 805 546 5456",
	createdAt: "12 Jan, 2022, 13:54:05 GMT",
};

export const providerTableData = [
	{
		id: "PR2873398429",
		name: "Patricia NG Limited",
		email: "dedokunmercy@yahoo.com",
		phone: "+234 809 348 1350",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "PR2873398429",
		name: "Patricia NG Limited",
		email: "dedokunmercy@yahoo.com",
		phone: "+234 809 348 1350",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "PR2873398429",
		name: "Patricia NG Limited",
		email: "dedokunmercy@yahoo.com",
		phone: "+234 809 348 1350",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "PR2873398429",
		name: "Patricia NG Limited",
		email: "dedokunmercy@yahoo.com",
		phone: "+234 809 348 1350",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "PR2873398429",
		name: "Patricia NG Limited",
		email: "dedokunmercy@yahoo.com",
		phone: "+234 809 348 1350",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "PR2873398429",
		name: "Patricia NG Limited",
		email: "dedokunmercy@yahoo.com",
		phone: "+234 809 348 1350",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "PR2873398429",
		name: "Patricia NG Limited",
		email: "dedokunmercy@yahoo.com",
		phone: "+234 809 348 1350",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "PR2873398429",
		name: "Patricia NG Limited",
		email: "dedokunmercy@yahoo.com",
		phone: "+234 809 348 1350",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
];
export const providerServicesTableData = [
	{
		id: "REF-2873-398429",
		email: "dedokunmercy@yahoo.com",
		method: "card",
		amount: 680400,
		status: "Success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "REF-2873-398429",
		email: "dedokunmercy@yahoo.com",
		method: "card",
		amount: 680400,
		status: "Success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "REF-2873-398429",
		email: "dedokunmercy@yahoo.com",
		method: "card",
		amount: 680400,
		status: "Success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "REF-2873-398429",
		email: "dedokunmercy@yahoo.com",
		method: "card",
		amount: 680400,
		status: "Success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "REF-2873-398429",
		email: "dedokunmercy@yahoo.com",
		method: "card",
		amount: 680400,
		status: "Success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "REF-2873-398429",
		email: "dedokunmercy@yahoo.com",
		method: "card",
		amount: 680400,
		status: "Success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "REF-2873-398429",
		email: "dedokunmercy@yahoo.com",
		method: "card",
		amount: 680400,
		status: "Success",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
];
export const providerPricingTableData = [
	{
		paymentType: "Card Service",
		chargingType: "Flat/Percentage",
		value: 400,
		cap: "No Cap",
		action: "Edit Service",
	},
	{
		paymentType: "Card Service",
		chargingType: "Flat/Percentage",
		value: 400,
		cap: "No Cap",
		action: "Edit Service",
	},
	{
		paymentType: "Card Service",
		chargingType: "Flat/Percentage",
		value: 400,
		cap: "No Cap",
		action: "Edit Service",
	},
];

export const providerIntegrationTableData = [
	{
		ipRange: "195.98.233.65",
		ipEnd: "195.98.233.128",
		NoOfAddress: 45,
		description: "Address for new authentications",
		action: "Edit Service",
	},
	{
		ipRange: "195.98.233.65",
		ipEnd: "195.98.233.128",
		NoOfAddress: 45,
		description: "Address for new authentications",
		action: "Edit Service",
	},
	{
		ipRange: "195.98.233.65",
		ipEnd: "195.98.233.128",
		NoOfAddress: 45,
		description: "Address for new authentications",
		action: "Edit Service",
	},
	{
		ipRange: "195.98.233.65",
		ipEnd: "195.98.233.128",
		NoOfAddress: 45,
		description: "Address for new authentications",
		action: "Edit Service",
	},
	{
		ipRange: "195.98.233.65",
		ipEnd: "195.98.233.128",
		NoOfAddress: 45,
		description: "Address for new authentications",
		action: "Edit Service",
	},
	{
		ipRange: "195.98.233.65",
		ipEnd: "195.98.233.128",
		NoOfAddress: 45,
		description: "Address for new authentications",
		action: "Edit Service",
	},
	{
		ipRange: "195.98.233.65",
		ipEnd: "195.98.233.128",
		NoOfAddress: 45,
		description: "Address for new authentications",
		action: "Edit Service",
	},
];

export const providerRouteTableData = [
	{
		route: "https://api.paystack.co/bank/resolve_bvn",
		cost: 300,
		method: "GET",
		priority: 3,
		action: "Edit Route",
	},
	{
		route: "https://api.paystack.co/bank/resolve_bvn",
		cost: 300,
		method: "GET",
		priority: 3,
		action: "Edit Route",
	},
	{
		route: "https://api.paystack.co/bank/resolve_bvn",
		cost: 300,
		method: "GET",
		priority: 3,
		action: "Edit Route",
	},
	{
		route: "https://api.paystack.co/bank/resolve_bvn",
		cost: 300,
		method: "GET",
		priority: 3,
		action: "Edit Route",
	},
	{
		route: "https://api.paystack.co/bank/resolve_bvn",
		cost: 300,
		method: "GET",
		priority: 3,
		action: "Edit Route",
	},
	{
		route: "https://api.paystack.co/bank/resolve_bvn",
		cost: 300,
		method: "GET",
		priority: 3,
		action: "Edit Route",
	},
	{
		route: "https://api.paystack.co/bank/resolve_bvn",
		cost: 300,
		method: "GET",
		priority: 3,
		action: "Edit Route",
	},
	{
		route: "https://api.paystack.co/bank/resolve_bvn",
		cost: 300,
		method: "GET",
		priority: 3,
		action: "Edit Route",
	},
	{
		route: "https://api.paystack.co/bank/resolve_bvn",
		cost: 300,
		method: "GET",
		priority: 3,
		action: "Edit Route",
	},
	{
		route: "https://api.paystack.co/bank/resolve_bvn",
		cost: 300,
		method: "GET",
		priority: 3,
		action: "Edit Route",
	},
	{
		route: "https://api.paystack.co/bank/resolve_bvn",
		cost: 300,
		method: "GET",
		priority: 3,
		action: "Edit Route",
	},
];

export const providerKeysTableData = [
	{
		key: "fvusfbvofs0nffe0f035403g",
		type: "Secret Keys",
		createdAt: "GET",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		key: "fvusfbvofs0nffe0f035403g",
		type: "Secret Keys",
		createdAt: "GET",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		key: "fvusfbvofs0nffe0f035403g",
		type: "Secret Keys",
		createdAt: "GET",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		key: "fvusfbvofs0nffe0f035403g",
		type: "Secret Keys",
		createdAt: "GET",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		key: "fvusfbvofs0nffe0f035403g",
		type: "Secret Keys",
		createdAt: "GET",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		key: "fvusfbvofs0nffe0f035403g",
		type: "Secret Keys",
		createdAt: "GET",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		key: "fvusfbvofs0nffe0f035403g",
		type: "Secret Keys",
		createdAt: "GET",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "12 Jan, 2022, 13:54:05 GMT",
	},
];
export const providerWebHooksTableData = [
	{
		webhook: "https://webhookurl/notifications/connect",
		key: "fvuoebfvosbfvo3tg09hrwv",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "",
	},
	{
		webhook: "https://webhookurl/notifications/connect",
		key: "fvuoebfvosbfvo3tg09hrwv",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "",
	},
	{
		webhook: "https://webhookurl/notifications/connect",
		key: "fvuoebfvosbfvo3tg09hrwv",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "",
	},
	{
		webhook: "https://webhookurl/notifications/connect",
		key: "fvuoebfvosbfvo3tg09hrwv",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "",
	},
	{
		webhook: "https://webhookurl/notifications/connect",
		key: "fvuoebfvosbfvo3tg09hrwv",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "",
	},
	{
		webhook: "https://webhookurl/notifications/connect",
		key: "fvuoebfvosbfvo3tg09hrwv",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "",
	},
	{
		webhook: "https://webhookurl/notifications/connect",
		key: "fvuoebfvosbfvo3tg09hrwv",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "",
	},
	{
		webhook: "https://webhookurl/notifications/connect",
		key: "fvuoebfvosbfvo3tg09hrwv",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "",
	},
	{
		webhook: "https://webhookurl/notifications/connect",
		key: "fvuoebfvosbfvo3tg09hrwv",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		expiredAt: "12 Jan, 2022, 13:54:05 GMT",
		action: "",
	},
];

const chartDefaults = {
	textStyle: {
		color: "#B2B6BA",
		fontFamily: "gilroy-regular",
		fontSize: 11,
		fontWeight: 500,
		overflow: "truncate",
		lineOverflow: "truncate",
	},
	tooltip: {
		trigger: "axis",
		axisPointer: {
			z: 10,
		},
	},
};

export const revenueStatsOptions = {
	xAxis: {
		type: "category",
		data: [
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
		],
	},
	yAxis: {
		type: "value",
		axisLabel: {
			formatter: "{value} M",
		},
		data: [2, 4, 6, 8, 10, 12, 14, 16, 18],
	},
	series: [
		{
			data: [2, 6, 7, 8, 11, 16, 18],
			type: "bar",
			name: "M",
			barWidth: 39,
			itemStyle: {
				color: "#2CC7A2",
			},
		},
		{
			data: [2, 6, 7, 8, 11, 16, 18],
			type: "bar",
			name: "M",
			barWidth: 39,
			itemStyle: {
				color: "#FFCB7D",
			},
		},
	],
};

export const totalTransactionsChartOptions = (chartData) => {
	return {
		...chartDefaults,
		color: [...chartData.map((data) => data.color)],
		grid: {
			bottom: 0,
			containLabel: true,
			left: 0,
			right: 0,
			top: 0,
		},
		series: [
			{
				name: "Total Transactions",
				type: "pie",
				radius: ["50%", "80%"],
				label: {
					show: false,
					position: "center",
				},
				selectedMode: "multiple",
				emphasis: {
					label: {
						show: true,
						fontSize: "25",
						fontWeight: "bold",
					},
				},
				labelLine: {
					show: false,
				},
				data: chartData,
			},
		],
		tooltip: {
			trigger: "item",
		},
	};
};