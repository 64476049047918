import styledComponents from "styled-components";

export const CustomToggleSwitchWrapper = styledComponents.div`
position: relative;
input{
    position: absolute;
    left : -99999px;
    top ; -999999px;
    &:checked + span {
        background: #FDEDED;
        &:before{
            left : 15px;
            background: #fff;
            box-shadow : 0 2px 4px 0 rgba(0, 35, 11,0.2) ;
        }
    }
}
span{
    display: flex;
    cursor: pointer;
    background-color: #FDEDED;
    position: relative;
    transition: background-color 0.2s;
    height: 22px;
    width: 38px;
    border-radius: 100px;
    &:before{
        content : "";
        position: absolute;
        top : 2px;
        left : 2px;
        width : 19px;
        height: 19px;
        border-radius : 21px;
        transition : 0.2s;
        background: red;
        box-shadow : 0 2px 4px 0 rgba(0, 35, 11,0.2) ;
    }
}
h5{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #39444F;
    font-family : "gilroy-medium";
}
`;
