import { toLower } from "lodash";
const basePath = "/banking/wallets";

const getTabRoutes = (walletId) => {
	return [
		{
			label: "Wallet Information",
			route: `${basePath}/${toLower(walletId)}/wallet-information`,
		},
		{
			label: "Cards",
			route: `${basePath}/${toLower(walletId)}/cards`,
		},
		{
			label: "Subscription",
			route: `${basePath}/${toLower(walletId)}/subscriptions`,
		},
		{
			label: "Payment Dispute",
			route: `${basePath}/${toLower(walletId)}/payment-disputes`,
		},
	];
};

export default getTabRoutes;
