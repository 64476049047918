import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toCurrency } from "utils/helpers";
import Table from "utils/Table/Table";
import BankingWalletFilters from "../common/BankingWalletFilters";
import {
	bankingWalletsTableData,
	bankingWalletsTableHeadings,
	bankingWalletsFiltersDefaultValues,
} from "../helpers/data";

const BankingWallets = () => {
	const history = useHistory();
	const [formValues, setFormValues] = useState({
		...bankingWalletsFiltersDefaultValues,
	});

	const handleChange = ({ target: { name, value } }) =>
		handleSelect(name, value);

	const handleSelect = (name, value) => {
		console.log({ name, value });
		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	const handleSubmit = () =>
		setFormValues({ ...bankingWalletsFiltersDefaultValues });

	const getWalletData = () =>
		bankingWalletsTableData?.map((transaction) => ({
			walletId: transaction?.walletId,
			customerId: transaction?.customerId,
			name: transaction?.name,
			walletType: transaction?.walletType,
			merchantNo: transaction?.merchantNo,
			balance: `₦ ${toCurrency(transaction?.balance)}`,
			transCost: transaction?.transCost,
			createdAt: transaction?.createdAt,
		}));

	const handleRowClick = (_, rowValues) => {
		history.push(
			`/banking/wallets/${rowValues.walletId.toLowerCase()}/wallet-information`
		);
	};
	return (
		<>
			<BankingWalletFilters
				formValues={formValues}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				handleSelect={handleSelect}
			/>
			<Table
				data={getWalletData()}
				headings={bankingWalletsTableHeadings}
				rowClick={handleRowClick}
			/>
		</>
	);
};

export default BankingWallets;
