export const userFiltersDefaultValues = {
	PaymentID: "",
	wallet: false,
	success: false,
	pending: false,
	failed: false,
	dateCreated: null,
};

export const profileInformationResponse = {
	role: "Finance",
	status: "Active",
	userId: "ADM27492294807",
	firstName: "Olatunji",
	lastName: "Olalekan",
	email: "info@patricia.com",
	phone: "+234 805 546 5456",
	createdAt: "12 Jan, 2022, 13:54:05 GMT",
};

export const activityLogResponse = {
	role: "Finance",
	activity: "View",
	module: "Aggregator",
	item: "CU974343",
	ip: "192.324.543.32",
	action: "aggregator/merchant/customer",
	createdAt: "12 Jan, 2022, 13:54:05 GMT",
	deviceUsed: "Desktop",
	browser: "Chrome",
	deviceOs: "Mac(Mac OS Big Sur)",
};

export const permissionResponse = [
	{name: 'Aggregator'},
	{name: 'Merchants'},
	{name: 'Customer'},
	{name: 'Payments'},
	{name: 'Banking'},
	{name: 'Providers'},
	{name: 'Pricing'},
	{name: 'Users'},
	{name: 'Settings'},
]

export const userTableHeadings = [
	"User ID",
	"User Name",
	"Email",
	"Phone Number",
	"Roles",
	"Status",
	"Date Added",
];

export const activiyLogTableHeadings = [
	"Date",
	"Activity Type",
	"Module",
	"Item",
	"IP Address",
	"Full Action",
];

export const loginActivityTableHeadings = [
	"Date",
	"Activity Type",
	"Device",
	"Attempts",
	"IP Address",
	"Status",
];

export const sessionTableHeadings = [
	"Date",
	"Total Time",
	"Login Time",
	"Logout Time",
	"IP Address",
	"Device",
	"Activities",
];

export const providerTableData = [
	{
		id: "WT2873398429",
		name: "Osinachukwu Blessed-man",
		email: "blessed-man@blusalt.net",
		phone: "+234 809 348 1350",
		Roles: "Admin",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "WT2873398429",
		name: "Osinachukwu Blessed-man",
		email: "blessed-man@blusalt.net",
		phone: "+234 809 348 1350",
		Roles: "Admin",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "WT2873398429",
		name: "Osinachukwu Blessed-man",
		email: "blessed-man@blusalt.net",
		phone: "+234 809 348 1350",
		Roles: "Admin",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "WT2873398429",
		name: "Osinachukwu Blessed-man",
		email: "blessed-man@blusalt.net",
		phone: "+234 809 348 1350",
		Roles: "Admin",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "WT2873398429",
		name: "Osinachukwu Blessed-man",
		email: "blessed-man@blusalt.net",
		phone: "+234 809 348 1350",
		Roles: "Admin",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "WT2873398429",
		name: "Osinachukwu Blessed-man",
		email: "blessed-man@blusalt.net",
		phone: "+234 809 348 1350",
		Roles: "Admin",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "WT2873398429",
		name: "Osinachukwu Blessed-man",
		email: "blessed-man@blusalt.net",
		phone: "+234 809 348 1350",
		Roles: "Admin",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "WT2873398429",
		name: "Osinachukwu Blessed-man",
		email: "blessed-man@blusalt.net",
		phone: "+234 809 348 1350",
		Roles: "Admin",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "WT2873398429",
		name: "Osinachukwu Blessed-man",
		email: "blessed-man@blusalt.net",
		phone: "+234 809 348 1350",
		Roles: "Admin",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "WT2873398429",
		name: "Osinachukwu Blessed-man",
		email: "blessed-man@blusalt.net",
		phone: "+234 809 348 1350",
		Roles: "Admin",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
	{
		id: "WT2873398429",
		name: "Osinachukwu Blessed-man",
		email: "blessed-man@blusalt.net",
		phone: "+234 809 348 1350",
		Roles: "Admin",
		status: "Active",
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
	},
];

export const activiyLogTableData = [
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "View",
		module: "Aggregator",
		item: "CU974343",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customer",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "View",
		module: "Aggregator",
		item: "CU974343",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customer",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "View",
		module: "Aggregator",
		item: "CU974343",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customer",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "View",
		module: "Aggregator",
		item: "CU974343",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customer",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "View",
		module: "Aggregator",
		item: "CU974343",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customer",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "View",
		module: "Aggregator",
		item: "CU974343",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customer",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "View",
		module: "Aggregator",
		item: "CU974343",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customer",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "View",
		module: "Aggregator",
		item: "CU974343",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customer",
	},
];

export const loginActivityTableData = [
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "Login",
		device: "Mac(Mac Os Big Sur)",
		attempt: "1",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customers",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "Login",
		device: "Mac(Mac Os Big Sur)",
		attempt: "1",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customers",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "Login",
		device: "Mac(Mac Os Big Sur)",
		attempt: "1",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customers",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "Login",
		device: "Mac(Mac Os Big Sur)",
		attempt: "1",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customers",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "Login",
		device: "Mac(Mac Os Big Sur)",
		attempt: "1",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customers",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "Login",
		device: "Mac(Mac Os Big Sur)",
		attempt: "1",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customers",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "Login",
		device: "Mac(Mac Os Big Sur)",
		attempt: "1",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customers",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "Login",
		device: "Mac(Mac Os Big Sur)",
		attempt: "1",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customers",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "Login",
		device: "Mac(Mac Os Big Sur)",
		attempt: "1",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customers",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "Login",
		device: "Mac(Mac Os Big Sur)",
		attempt: "1",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customers",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		activity: "Login",
		device: "Mac(Mac Os Big Sur)",
		attempt: "1",
		ip: "192.324.543.32",
		action: "aggregator/merchant/customers",
	},
];

export const sessionsTableData = [
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		totalTime: "15 Mins: 32sec",
		loginTime: "13:27:06 GMT",
		logoutTime: "13:27:06 GMT",
		ip: "192.324.543.32",
		attempt: "Mac(Mac OS Big Sur)",
		activities: "10",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		totalTime: "15 Mins: 32sec",
		loginTime: "13:27:06 GMT",
		logoutTime: "13:27:06 GMT",
		ip: "192.324.543.32",
		attempt: "Mac(Mac OS Big Sur)",
		activities: "10",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		totalTime: "15 Mins: 32sec",
		loginTime: "13:27:06 GMT",
		logoutTime: "13:27:06 GMT",
		ip: "192.324.543.32",
		attempt: "Mac(Mac OS Big Sur)",
		activities: "10",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		totalTime: "15 Mins: 32sec",
		loginTime: "13:27:06 GMT",
		logoutTime: "13:27:06 GMT",
		ip: "192.324.543.32",
		attempt: "Mac(Mac OS Big Sur)",
		activities: "10",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		totalTime: "15 Mins: 32sec",
		loginTime: "13:27:06 GMT",
		logoutTime: "13:27:06 GMT",
		ip: "192.324.543.32",
		attempt: "Mac(Mac OS Big Sur)",
		activities: "10",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		totalTime: "15 Mins: 32sec",
		loginTime: "13:27:06 GMT",
		logoutTime: "13:27:06 GMT",
		ip: "192.324.543.32",
		attempt: "Mac(Mac OS Big Sur)",
		activities: "10",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		totalTime: "15 Mins: 32sec",
		loginTime: "13:27:06 GMT",
		logoutTime: "13:27:06 GMT",
		ip: "192.324.543.32",
		attempt: "Mac(Mac OS Big Sur)",
		activities: "10",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		totalTime: "15 Mins: 32sec",
		loginTime: "13:27:06 GMT",
		logoutTime: "13:27:06 GMT",
		ip: "192.324.543.32",
		attempt: "Mac(Mac OS Big Sur)",
		activities: "10",
	},
	{
		createdAt: "12 Jan, 2022, 13:54:05 GMT",
		totalTime: "15 Mins: 32sec",
		loginTime: "13:27:06 GMT",
		logoutTime: "13:27:06 GMT",
		ip: "192.324.543.32",
		attempt: "Mac(Mac OS Big Sur)",
		activities: "10",
	},
];

export const permissionTableData = [
	{
		module: "Payment",
		id: "PER52DEGIF",
	},
	{
		module: "Banking",
		id: "PER52DEGIF",
	},
	{
		module: "Aggregators",
		id: "PER52DEGIF",
	},
	{
		module: "Merchants",
		id: "PER52DEGIF",
	},
	{
		module: "Customers",
		id: "PER52DEGIF",
	},
	{
		module: "Providers",
		id: "PER52DEGIF",
	},
	{
		module: "Pricing",
		id: "PER52DEGIF",
	},
	{
		module: "Report",
		id: "PER52DEGIF",
	},
	{
		module: "Settings",
		id: "PER52DEGIF",
	},
	{
		module: "Admin",
		id: "PER52DEGIF",
	},
];