import { revenueStatsOptions } from "components/Dashboard/Providers/helpers/data";
import ChartGraph from "utils/Charts/ChartGraph/ChartGraph";
import "./ProviderOverviewBarChart.scss";

const ProviderOverviewBarChart = () => {
	return (
		<div className="revenue-container pt-5">
			<p className="title px-5">Provider Statistics</p>

			<ChartGraph
				option={revenueStatsOptions}
				styles={{
					height: "472px",
				}}
			/>
		</div>
	);
};

export default ProviderOverviewBarChart;
