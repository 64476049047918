import "./TransactionDistribution.scss";

const TransactionDistribution = ({ data }) => {
	return (
		<div className="col-xl-4 col-md-6 ">
			<div className="transaction-distribution p-4 mb-4">
				<h2>{data?.name}</h2>
				<p>{data?.value}</p>
			</div>
		</div>
	);
};

export default TransactionDistribution;
