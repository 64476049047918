export const bankingDashboardAnalyticsData = [
	{
		title: "Number of Accounts",
		amount: "839,394,394",
		increase: "+6.48%",
	},
	{
		title: "Number of Wallets",
		amount: "839,394,394",
		increase: "+6.48%",
	},
	{
		title: "Transfer Value",
		amount: "839,394,394",
		increase: "+6.48%",
	},
	{
		title: "Number of Transfers",
		amount: "839,394,394",
		increase: "+6.48%",
	},
];

export const virtualAccountDistributionOptions = {
	color: ["#6ADAB2", "#D174FC", "#FC8787", "#4ACFF9"],
	tooltip: {
		trigger: "item",
	},
	legend: {
		show: false,
	},
	series: [
		{
			name: "Virtual Account Distribution",
			type: "pie",
			radius: "50%",
			data: [
				{ value: 735, name: "NGN (₦)" },
				{ value: 580, name: "USD ($)" },
				{ value: 484, name: "EUR (£)" },
				{ value: 300, name: "GDP (£)" },
			],
			emphasis: {
				itemStyle: {
					shadowBlur: 10,
					shadowOffsetX: 0,
					shadowColor: "rgba(0, 0, 0, 0.5)",
				},
			},
		},
	],
};

export const highestTransfersHeading = [
	"Aggregator Name",
	"AMOUNT",
	"Wallet ID",
];

export const highestTransferData = [
	{
		name: "Adedokun Mercy",
		amount: "₦ 680,400",
		walletId: "WT27492294807",
	},
	{
		name: "Adedokun Mercy",
		amount: "₦ 680,400",
		walletId: "WT27492294807",
	},
	{
		name: "Adedokun Mercy",
		amount: "₦ 680,400",
		walletId: "WT27492294807",
	},
];

export const totalTransferValueOptions = {
	xAxis: {
		type: "category",
		grid: {
			bottom: 0,
			containLabel: true,
			left: 0,
			right: 0,
			top: 0,
		},
		data: [
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
			"Mar 12",
		],
		axisLabel: {
			show: true,
		},
		axisTick: {
			show: false,
		},
		splitLine: {
			show: false,
		},
		axisLine: {
			show: false,
		},
	},
	yAxis: {
		type: "value",
		axisLabel: {
			formatter: "{value} M",
		},
		axisTick: {
			show: false,
		},
		axisLine: {
			show: false,
		},
		axisPointer: {
			show: true,
			type: "none",
			color: "#E2E4E5",
		},
		data: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20],
	},
	series: [
		{
			data: [10, 6, 7, 8, 11, 16, 18],
			type: "bar",
			name: "M",
			barWidth: 39,
			stack: "total",
			color: "#F1AD87",
		},
	],
};
