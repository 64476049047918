import ReactECharts from "echarts-for-react";

const style = {
	width: "100%",
	margin: "auto",
	height: "auto",
	minWidth: "100%",
};

const ChartGraph = ({ styles = {}, option, opts = { renderer: "svg" } }) => {
	return (
		<ReactECharts
			option={option}
			opts={opts}
			style={{ ...style, ...styles }}
		/>
	);
};

export default ChartGraph;
