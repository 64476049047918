import { useEffect, useRef } from "react";

const ScrollToTop = () => {
	const topRef = useRef(null);

	useEffect(() => {
		topRef.current.scrollIntoView({ behavior: "smooth" });
	});

	return <div ref={topRef} />;
};

export default ScrollToTop;
