import { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Routes from "components/RoutesWrapper/routes";
import { ReactComponent as SearchIcon } from "assets/images/icons/search.svg";
import { ReactComponent as AlertIcon } from "assets/images/icons/alert.svg";
import { activeRoute, matchFunction } from "utils/helpers";

const Header = () => {
	const { pathname } = useLocation();
	const [currentRoute, setCurrentRoue] = useState({});

	useEffect(() => {
		const newRoute = Routes.find((route) =>
			matchFunction(route.path, pathname, route.exact)
		);
		setCurrentRoue(newRoute);
	}, [pathname]);

	return (
		<div className="header">
			<h4 className="ms-4 fw-bold mb-0">{currentRoute?.name}</h4>

			<div className="tabbed__header">
				{currentRoute?.tabs?.map(
					(tab) =>
						!tab.hide && (
							<div key={tab.name} role="tablist">
								<NavLink
									to={tab.path}
									isActive={() =>
										activeRoute(
											tab.path,
											pathname,
											currentRoute.tabs
										)
									}
								>
									{tab?.name}
								</NavLink>
							</div>
						)
				)}
			</div>
			<div className="right__headers">
				<Link to="/notifications">
					<SearchIcon />
				</Link>

				<Link to="/notifications">
					<AlertIcon />
				</Link>
			</div>
		</div>
	);
};

export default Header;
