import { useState } from "react";

import { ReactComponent as CalenderIcon } from "assets/images/icons/calendar.svg";
import SmallSelect from "utils/select/SmallSelect/SmallSelect";

export default function DateSelect({
	cls,
	wrapperCls,
	onChange,
	// endDate,
	formLabel,
	label,
	name,
	options,
	value,
}) {
	const [, setShowDatePicker] = useState(false);
	// const handleDatePickerChange = (newValue) => {
	//     onChange(newValue[0])
	// }

	return (
		<>
			{/* {showDatePicker && (
                <CustomDatePicker 
                    cls="inline-datepicker date--select-date-picker"
                    value={value?.value} 
                    onChange={handleDatePickerChange} 
                    selected={new Date(value)}
                    startDate={value}
                    endDate={endDate}
                    name={name}
                    selectRange
                />
            )} */}

			<div className={`flex__between date-select ${wrapperCls}`}>
				<div
					className="pointer calender-icon-container"
					onClick={() => setShowDatePicker((prev) => !prev)}
				>
					<CalenderIcon className="mx-3" />
				</div>
				<div className={cls}>
					<SmallSelect
						name={name}
						label={label}
						formLabel={formLabel}
						onChange={({ target: { value } }) => onChange(value)}
						options={options}
						value={value}
					/>
				</div>
			</div>
		</>
	);
}
