import { Link, useHistory } from "react-router-dom";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
// import eyeOff from "assets/images/icons/eye-off.svg";
import "assets/styles/scss/auth.scss";
import CustomPassword from "utils/CustomPassword/CustomPassword";
const Login = () => {
  const history = useHistory();
  return (
    <div className="auth-container flex__center">
      <div className="auth-card col-lg-3 col-md-5 col-sm-5 col-11 shadow-lg flex__between">
        <div className="text-center">
          <h3 className="fw-bold">Admin Login Portal</h3>
          <h5>
            Your password is private and should not be <br /> compromised in any
            way
          </h5>
        </div>
        <form action="">
          <CustomInputBox
            label="Email Address"
            placeholder="Enter your email Address"
          />
          <CustomPassword label="Password" placeholder="Your access Password" />
          <Link className="text-danger fw-bold" to={"/forgot-password"}>
            I can’t remember my password
          </Link>

          <div className="d-grid mt-4">
            <button
              className="btn btn--primary"
              type="button"
              onClick={() => history.push("/")}
            >
              Login to Dashboard
            </button>
          </div>
        </form>
        <div>
          <Link to={"/"}>
            Need Help? <b>Contact Support</b>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
