import ChartGraph from "utils/Charts/ChartGraph/ChartGraph";
import { revenueStatsOptions } from "../utils/data";
import "./RevenueStatistics.scss";

const RevenueStatistics = () => {
	return (
		<div className="revenue-container pt-5">
			<p className="title px-5">Revenue Statistics</p>

			<ChartGraph
				option={revenueStatsOptions}
				styles={{
					height: "472px",
				}}
			/>
		</div>
	);
};

export default RevenueStatistics;
