import { ReactComponent as BuildingIcon } from "assets/images/icons/buildings.svg";
import providerImg from "assets/images/provider-img.jpg";
import CustomToggleSwitch from "utils/CustomToggleSwitch/CustomToggleSwitch";
function ProviderOverviewDetails({ res }) {
	return (
		<div className="row">
			<div className="col-md-2 mb-4">
				<img src={providerImg} alt="avatar" width={200} />

				<div className="bg-dark  px-3 mt-4 rounded flex__start">
					<span>
						<CustomToggleSwitch />
					</span>
					<span className="text-light fs-3">Black List Customer</span>
				</div>
			</div>
			<div className="col-lg-6">
				<div className="col-md-10 info__section">
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Provider ID
						</p>
						<p className="value justify-content-end">
							{res?.providerId}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Provider Name
						</p>
						<p className="value justify-content-end">
							{res?.providerName}
						</p>
					</section>

					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Email
						</p>
						<p className="value justify-content-end">
							{res?.email}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Phone Number
						</p>
						<p className="value justify-content-end">
							{res?.phone}
						</p>
					</section>
					<section>
						<p className="key text-uppercase">
							<BuildingIcon /> Date Created
						</p>
						<p className="value justify-content-end">
							{res?.createdAt}
						</p>
					</section>
				</div>
			</div>
		</div>
	);
}

export default ProviderOverviewDetails;
