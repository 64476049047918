import Modal from "utils/Modal/Modal";
import { ReactComponent as BuildingIcon } from "assets/images/icons/buildings.svg";
import { activityLogResponse } from "../../helpers/data";

const ActivityLogModal = ({ res = activityLogResponse, toggle, IDTabName }) => {
	return (
		<Modal size="md" toggle={toggle} title={IDTabName}>
			<div className="px-4">
				<div className="row border-bottom">
					<div className="col-12 px-5 py-3 ">
						<div className="info__section">
							<h4 className="fw-bold text-uppercase">
								Log Details
							</h4>
							<section className="mt-4">
								<p className="key text-uppercase">
									<BuildingIcon /> Admin Role
								</p>
								<p className="value justify-content-end">
									{res?.role}
								</p>
							</section>
							<section>
								<p className="key text-uppercase">
									<BuildingIcon /> IP Address
								</p>
								<p className="value justify-content-end">
									{res?.ip}
								</p>
							</section>
							<section>
								<p className="key text-uppercase">
									<BuildingIcon /> Device Used
								</p>
								<p className="value justify-content-end">
									{res?.deviceUsed}
								</p>
							</section>
							<section>
								<p className="key text-uppercase">
									<BuildingIcon /> Device Os
								</p>
								<p className="value justify-content-end">
									{res?.deviceOs}
								</p>
							</section>
							<section>
								<p className="key text-uppercase">
									<BuildingIcon /> Broswer
								</p>
								<p className="value justify-content-end">
									{res?.browser}
								</p>
							</section>

							<section>
								<p className="key text-uppercase">
									<BuildingIcon />
									Acitivity Date
								</p>
								<p className="value justify-content-end">
									{res?.createdAt}
								</p>
							</section>
						</div>
					</div>
				</div>
				<div className="row ">
					<div className="col-12 px-5 py-4 ">
						<div className="info__section">
							<h4 className="fw-bold text-uppercase">
								Activity Information
							</h4>
							<section className="mt-4">
								<p className="key text-uppercase">
									<BuildingIcon /> Activity Type
								</p>
								<p className="value justify-content-end">
									{res?.activity}
								</p>
							</section>
							<section>
								<p className="key text-uppercase">
									<BuildingIcon /> Module
								</p>
								<p className="value justify-content-end">
									{res?.module}
								</p>
							</section>
							<section>
								<p className="key text-uppercase">
									<BuildingIcon /> Full Action
								</p>
								<p className="value justify-content-end">
									{res?.action}
								</p>
							</section>

							<section>
								<p className="key text-uppercase">
									<BuildingIcon /> Item
								</p>
								<span className="value justify-content-end text-primary bg--faint-primary p-3 w-auto float-right ml-auto">
									{res?.item}
								</span>
							</section>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ActivityLogModal;
