import styled from "styled-components";

export const ModalLayout = styled.section`
  height: 100%;

  .id-container {
    background-color: ${({ colors }) => `${colors?.primaryColor}10`};

    &.primary {
      color: ${({ colors }) => colors?.primaryColor};

      circle,
      path {
        stroke: ${({ colors }) => colors?.primaryColor};
        fill: none;
      }
    }
  }

  // MODAL LAYOUT

  .modal-body .modal--activation-aside {
    background-color: ${({ colors }) => colors?.primaryColor} !important;

    .normal__header .subtitle {
      color: white !important;
    }
  }

  // DIRECTOR FORM

  .modal-body #modal--main-section .single-director-form {
    background-color: ${({ colors }) => colors?.accentColor} !important;

    .form-header {
      color: ${({ colors }) => colors?.accentTextColor} !important;

      svg path {
        stroke: ${({ colors }) => colors?.accentTextColor} !important;
      }
    }
  }

  // MODAL PRIMARY HEADER

  .primary__header {
    .title {
      color: ${({ colors }) => colors?.primaryColor};
      font-weight: bold;
    }

    .subtitle {
      color: ${({ colors }) => `${colors?.primaryColor}60`};
    }
  }

  // ASIDE STEP CONTAINER

  .aside-step-item {
    .title {
      color: ${({ colors }) => `${colors?.secColor}90`};
    }

    .subtitle {
      color: ${({ colors }) => `${colors?.secColor}CC`};
    }

    svg {
      circle {
        fill: ${({ colors }) => `${colors?.secColor}BB`};
      }

      path {
        fill: ${({ colors }) => colors?.secColor};
      }
    }

    &.current {
      .title {
        color: white;
      }

      .subtitle {
        color: white;
      }

      svg {
        circle {
          fill: white;
        }

        path {
          fill: ${({ colors }) => colors?.primaryColor};
        }
      }
    }

    &.prev {
      .title {
        color: #2cc7a2;
      }

      svg circle {
        fill: #2cc7a2;
      }
    }
  }

  // BACKGROUND COLORS

  .bg--primary {
    background-color: ${({ colors }) => colors?.primaryColor};
  }

  .bg--secondary {
    background-color: ${({ colors }) => colors?.secColor};
  }

  .bg--faint-primary {
    background-color: ${({ colors }) => `${colors?.primaryColor}10`};
  }

  .bg--faint-secondary {
    background-color: ${({ colors }) => `${colors?.secColor}10`};
  }

  // MAIN COLORS

  .primary {
    color: ${({ colors }) => colors?.primaryColor};

    circle,
    path {
      fill: ${({ colors }) => colors?.primaryColor};
    }
  }

  .secondary {
    color: ${({ colors }) => colors?.secColor};

    circle,
    path {
      fill: ${({ colors }) => colors?.secColor};
    }
  }

  .gradient {
    color: ${({ colors }) => `${colors?.primaryColor}AA`};

    circle,
    path {
      fill: ${({ colors }) => `${colors?.primaryColor}AA`};
    }
  }

  .stroke-primary {
    color: ${({ colors }) => colors?.primaryColor};

    circle,
    path {
      stroke: ${({ colors }) => colors?.primaryColor};
    }
  }

  .stroke-secondary {
    color: ${({ colors }) => colors?.secColor};

    circle,
    path {
      stroke: ${({ colors }) => colors?.secColor};
    }
  }

  .faint-gradient {
    color: ${({ colors }) => `${colors?.primaryColor}10`};
  }
  .faint-secondary {
    color: ${({ colors }) => `${colors?.secColor}10`};
  }

  .value--link,
  .stroke-gradient {
    color: ${({ colors }) => `${colors?.primaryColor}AA`};

    circle,
    path {
      stroke: ${({ colors }) => `${colors?.primaryColor}AA`};
    }
  }

  .next-step-icon {
    circle {
      stroke: ${({ colors }) => colors?.primaryColor};
      fill: ${({ colors }) => `${colors?.secColor}30`};
    }

    path {
      fill: ${({ colors }) => colors?.primaryColor};
    }
  }

  .circle-secondary-only circle {
    fill: ${({ colors }) => colors?.secColor} !important;
  }

  .faint-secondary circle {
    fill: ${({ colors }) => `${colors?.secColor}40`} !important;
  }

  // PROGRESS BAR

  .progress .secondary {
    background-color: ${({ colors }) => `${colors?.secColor}40`};

    .bg-secondary {
      background-color: ${({ colors }) => colors?.secColor} !important;
    }
  }

  // BUTTONS

  .btn--primary,
  .btn--primary:hover {
    background: ${({ colors }) => colors?.primaryColor};
    color: ${({ colors }) => colors?.primaryTextColor};

    svg path {
      stroke: ${({ colors }) => colors?.primaryTextColor};
    }
  }

  .btn--primary--bordered,
  .btn--primary--bordered:hover {
    color: ${({ colors }) => colors?.primaryColor};
    border: ${({ colors }) =>
      colors?.primaryColor ? `1px solid ${colors?.primaryColor}` : ""};

    svg path {
      stroke: ${({ colors }) => colors?.primaryColor};
    }
  }

  .btn--secondary,
  .btn--secondary:hover {
    background-color: ${({ colors }) => colors?.secColor};
    color: ${({ colors }) => colors?.secTextColor};

    svg path {
      stroke: ${({ colors }) => colors?.secTextColor};
    }
  }

  .btn--gradient,
  .btn--gradient:hover {
    background: ${({ colors }) => colors?.primaryColor};
  }

  .btn--faint-gray,
  .btn--faint-gray:hover {
    color: ${({ colors }) => colors?.primaryColor};

    svg path {
      stroke: ${({ colors }) => colors?.primaryColor};
    }
  }

  .btn--faint-gradient,
  .btn--faint-gradient:hover {
    background-color: ${({ colors }) => `${colors?.primaryColor}10`};
    color: ${({ colors }) => `${colors?.primaryColor}AA`};
  }

  .btn--secondary--bordered,
  .btn--secondary--bordered:hover {
    border: ${({ colors }) =>
      colors?.secColor ? `1px solid ${colors?.secColor}60` : ""};
  }

  // INPUT FIELDS

  .form-group:hover,
  .form-group:focus-within {
    .info-text,
    .form-group-label {
      color: ${({ colors }) => colors?.primaryColor};

      .question {
        color: ${({ colors }) => colors?.primaryColor};
      }
    }

    .input-wrapper {
      border: ${({ colors }) =>
        colors?.primaryColor ? `1px solid ${colors?.primaryColor}` : ""};
    }
  }

  .secondary-input {
    .input-wrapper {
      border: ${({ colors }) =>
        colors?.secColor ? `1px solid ${colors?.secColor}60` : ""};

      svg path {
        fill: ${({ colors }) => colors?.primaryColor} !important;
      }
    }
  }

  // SELECT FIELDS

  .form-group-select,
  .form-group-select_error,
  .form-group-select--is-multi,
  .form-group-select--is-multi_error {
    [class*="-control"] {
      &:hover,
      &:focus-within {
        border-color: ${({ colors }) => colors?.primaryColor} !important;
        color: ${({ colors }) => colors?.primaryColor} !important;

        svg path {
          fill: ${({ colors }) => colors?.primaryColor} !important;
        }
      }
    }

    [class*="-option"]:hover {
      background-color: ${({ colors }) => colors?.primaryColor} !important ;
    }
  }

  .secondary-select {
    .input-wrapper {
      border-color: ${({ colors }) => `${colors?.secColor}60`};

      .form-group-select,
      .form-group-select--is-multi {
        [class*="-control"] svg path {
          fill: ${({ colors }) => `${colors?.secColor}60`} !important;
        }

        &:hover,
        &:focus-within {
          [class*="-control"] svg path {
            fill: ${({ colors }) => colors?.primaryColor} !important;
          }
        }
      }
    }
  }

  // RADIOS

  .custom-radio {
    input[type="radio"] {
      border: ${({ colors }) =>
        colors?.secColor ? `0.5px solid ${colors?.secColor}` : ""};

      &:checked::before {
        box-shadow: ${({ colors }) =>
          colors?.secColor ? `inset 1em 1em ${colors?.secColor}` : ""};
      }
    }

    &:hover {
      color: ${({ colors }) => colors?.secColor};
    }
  }

  // CHECKBOXES

  .custom-check-box {
    input {
      background-color: ${({ colors }) => `${colors?.secColor}0D`};
      border: ${({ colors }) =>
        colors?.primaryColor ? `0.5px solid ${colors?.primaryColor}` : ""};

      &:checked {
        background-color: ${({ colors }) => colors?.primaryColor};
      }
    }

    label:hover {
      color: ${({ colors }) => colors?.primaryColor};
    }

    &.secondary {
      input {
        border: ${({ colors }) =>
          colors?.secColor ? `0.5px solid ${colors?.secColor}` : ""};

        &:checked {
          background-color: ${({ colors }) => colors?.secColor};
        }
      }

      &:hover {
        label {
          color: ${({ colors }) => colors?.secColor};
        }
      }
    }
  }

  // DATE PICKER

  .input-container-wrapper {
    .input-wrapper:hover,
    .input-wrapper:focus-within {
      border: ${({ colors }) =>
        colors?.primaryColor ? `1px solid ${colors?.primaryColor}` : ""};

      .react-datepicker__close-icon::after {
        background-color: ${({ colors }) => colors?.primaryColor};
      }
    }

    .react-datepicker__close-icon::after {
      background-color: ${({ colors }) => colors?.secColor};
    }

    &.date-input:hover,
    &.date-input:focus-within {
      svg path {
        fill: ${({ colors }) => colors?.primaryColor};
      }
    }
  }

  // LINKS

  a:hover,
  .link:hover {
    color: ${({ colors }) => colors?.primaryColor};
  }

  a:visited,
  .link:visited {
    color: ${({ colors }) => colors?.secColor};
  }

  // UNDERLINED INPUTS

  .input--underlined {
    .input-wrapper {
      &:hover,
      &:focus-within {
        border: none;
        border-bottom: ${({ colors }) =>
          colors?.primaryColor ? `1px solid ${colors?.primaryColor}` : ""};
      }
    }

    .form-group-select,
    .form-group-select--is-multi {
      [class*="-control"] {
        &:hover,
        &:focus-within {
          color: ${({ colors }) => colors?.primaryColor} !important;
          border-color: ${({ colors }) => colors?.primaryColor} !important;
        }
      }
    }

    .form-group-select_error,
    .form-group-select--is-multi_error {
      [class*="-control"] {
        &:hover,
        &:focus-within {
          color: ${({ colors }) => colors?.primaryColor} !important;
          border-color: ${({ colors }) => colors?.primaryColor} !important;
        }
      }
    }
  }
`;
