import { useState } from "react";
import { toCurrency } from "utils/helpers";
import Table from "utils/Table/Table";
import WalletInfoModal from "../../common/modals/WalletInfoModal";
import BankingWalletFilters from "../../common/BankingWalletFilters";
import {
	bankingWalletsTableData,
	bankingWalletsTableHeadings,
	bankingWalletsFiltersDefaultValues,
} from "../../helpers/data";

const WalletInfoTable = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [currentRowValues, setCurrentRowValues] = useState({});
	const [formValues, setFormValues] = useState({
		...bankingWalletsFiltersDefaultValues,
	});

	const toggleModal = () => setIsOpen((prev) => !prev);

	const handleChange = ({ target: { name, value } }) =>
		handleSelect(name, value);

	const handleSelect = (name, value) => {
		console.log({ name, value });
		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	const handleSubmit = () =>
		setFormValues({ ...bankingWalletsFiltersDefaultValues });

	const getWalletData = () =>
		bankingWalletsTableData?.map((transaction) => ({
			walletId: transaction?.walletId,
			customerId: transaction?.customerId,
			name: transaction?.name,
			walletType: transaction?.walletType,
			merchantNo: transaction?.merchantNo,
			balance: `₦ ${toCurrency(transaction?.balance)}`,
			transCost: transaction?.transCost,
			createdAt: transaction?.createdAt,
		}));

	const handleRowClick = (_, rowValues) => {
		const transactionData = bankingWalletsTableData?.find(
			(transaction) => transaction?.reference === rowValues?.transactionID
		);
		setCurrentRowValues({
			...rowValues,
			...transactionData,
		});
		toggleModal();
	};
	return (
		<>
			<BankingWalletFilters
				formValues={formValues}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				handleSelect={handleSelect}
			/>
			<Table
				data={getWalletData()}
				headings={bankingWalletsTableHeadings}
				rowClick={handleRowClick}
			/>
			{isOpen && (
				<WalletInfoModal
					toggle={toggleModal}
					payment={currentRowValues}
					IDTabName={`Transaction Information`}
				/>
			)}
		</>
	);
};

export default WalletInfoTable;
