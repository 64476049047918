import timer from "assets/images/icons/timer-start.svg";
import more from "assets/images/icons/more-3.svg";
import timerFailed from "assets/images/icons/timer-start-danger.svg";
import "./WatcherTransactionCard.scss";

function WatcherTransactionCard({ watcher }) {
	return (
		<div className="watcher-reg-card p-3 mb-4">
			<div className="flex__between">
				<div className="text-desc">
					<h6>
						<span>
							<img
								src={watcher.status ? timer : timerFailed}
								alt="timer"
							/>
						</span>
						<span className="ml-4">{watcher.timer}</span>
					</h6>
					<p className="mt-3">
						{watcher.amount} &#9679; {watcher.desc}
					</p>
				</div>
				<div className="icon-desc">
					<img src={more} alt="timer" className="mb-3 pointer" />
				</div>
			</div>
		</div>
	);
}

export default WatcherTransactionCard;
