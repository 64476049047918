import { useState } from "react";
import Table from "utils/Table/Table";
import ActivityLogModal from "../../common/modals/ActivityLogModal";
import UsersFilters from "../../common/UsersFilters";
import {
	userFiltersDefaultValues,
	activiyLogTableData,
	activiyLogTableHeadings,
} from "../../helpers/data";

const ActivityLog = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [currentRowValues, setCurrentRowValues] = useState({});
	const [formValues, setFormValues] = useState({
		...userFiltersDefaultValues,
	});

	const toggleModal = () => setIsOpen((prev) => !prev);

	const handleChange = ({ target: { name, value } }) =>
		handleSelect(name, value);

	const handleSelect = (name, value) => {
		console.log({ name, value });
		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	const handleSubmit = () => setFormValues({ ...userFiltersDefaultValues });

	const getActivityData = () => {
		return activiyLogTableData.map((data) => ({
			...data,
			item: (
				<span className="text-primary text-underline">{data.item}</span>
			),
		}));
	};

	const handleRowClick = (_, rowValues) => {
		// history.push(`/providers/${rowValues.transactionID}/overview`);
		const activityData = activiyLogTableData?.find(
			(transaction) => transaction?.id === rowValues?.id
		);
		setCurrentRowValues({
			...rowValues,
			...activityData,
		});
		toggleModal();
	};
	return (
		<>
			<div className="position-relative">
				<UsersFilters
					formValues={formValues}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					handleSelect={handleSelect}
				/>
				<Table
					data={getActivityData()}
					headings={activiyLogTableHeadings}
					rowClick={handleRowClick}
				/>
				{isOpen && (
					<ActivityLogModal
						toggle={toggleModal}
						payment={currentRowValues}
						IDTabName={`Activity Log Details`}
					/>
				)}
			</div>
		</>
	);
};

export default ActivityLog;
