import SectionWrapper from "components/common/section/SectionWrapper/SectionWrapper";
import DetailsHeader from "components/common/Details/DetailsHeader/DetailsHeader";
import { ReactComponent as LinkIcon } from "assets/images/icons/link.svg";
import getTabRoutes from "../../helpers/tabs";
import { useHistory, useParams } from "react-router-dom";
import { providerResponse } from "../../helpers/data";
import ProviderOverviewDetails from "../ProviderOverview/ProviderOverviewDetails";
import ProviderIpTable from "./ProviderIpTable";
import SideTabs from "utils/SideTabs/SideTabs";
import ProviderRouteTable from "./ProviderRouteTable";
import ProviderKeysTable from "./ProviderKeysTable";
import ProviderWebHooksTable from "./ProviderWebHooksTable";

function ProviderIntegrations() {
	const history = useHistory();
	const { id } = useParams();
	return (
		<SectionWrapper
			cls="pb-0"
			containerCls="d-flex flex-wrap"
			routeName={
				<DetailsHeader
					detailID={id}
					icon={<LinkIcon className="me-3" />}
					IDName="Provider Id"
					returnAction={() => history.push("/providers")}
					tabName="Providers"
				/>
			}
			tabs={getTabRoutes(id)}
			navTabChildren={
				<div className="d-flex">
					<button className="btn btn--sm btn--secondary--bordered me-4"></button>
					<button className="btn btn--sm btn--secondary--bordered me-4"></button>
				</div>
			}
			upperDiv={<ProviderOverviewDetails res={providerResponse} />}
		>
			<SideTabs
				tabComponent={[
					{
						name: "IP Address",
						id: "ipAddress",
						Comp: <ProviderIpTable />,
					},
					{
						name: "Route",
						id: "route",
						Comp: <ProviderRouteTable />,
					},
					{
						name: "Keys",
						id: "keys",
						Comp: <ProviderKeysTable />,
					},
					{
						name: "WebHooks",
						id: "webhook",
						Comp: <ProviderWebHooksTable />,
					},
				]}
			/>
		</SectionWrapper>
	);
}

export default ProviderIntegrations;
