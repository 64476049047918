import ChartStatus from "components/common/ChartStatus/ChartStatus";
import ChartGraph from "utils/Charts/ChartGraph/ChartGraph";
import { totalTransactionsChartOptions } from "../../DashboardPayment/utils/data";

const data = [
	{
		color: "#F18A87",
		value: 200,
		name: "Expired Cards",
		cls: "recent-success-icon ",
	},
	{
		color: "#2CC7A2",
		value: 250,
		name: "MasterCard",
		cls: "recent-danger-icon ",
	},
];

const IssuedCardStatistics = () => {
	return (
		<>
			<div className="flex__center">
				<ChartGraph
					option={totalTransactionsChartOptions(data)}
					styles={{
						height: "255px",
					}}
				/>
			</div>
			<div className="px-2 pb-3">
				{data.map(({ value, name, cls }) => (
					<ChartStatus value={value} name={name} cls={cls} />
				))}
			</div>
		</>
	);
};

export default IssuedCardStatistics;
