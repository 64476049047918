import { useState } from "react";
import DateSelect from "utils/select/DateSelect/DateSelect";
import { infoStatsDateOptions } from "../DashboardPayment/utils/data";
import { ReactComponent as Location } from "assets/images/icons/location.svg";
import PaymentDashboardAnalyticsCard from "../DashboardPayment/utils/Cards/PaymentDashboardAnalyticsCard";
import {
	locationsData,
	merchantDashboardAnalyticsData,
} from "./components/data";
import NewRegistrations from "./components/NewRegistrations";
import DashStatistics from "../DashboardAggregators/components/DashStatistics";
import TopMerchants from "../DashboardAggregators/components/TopMerchants";
import ActiveMerchant from "./components/ActiveMerchant";

const DashboardMerchants = () => {
	const [dateRange, setDateRange] = useState(
		"This Week (12 Mar, 2022 - 18 Mar, 2022)"
	);
	return (
		<div>
			<div className="pl-4 d-flex  mb-3">
				<DateSelect
					cls="col-9 col-sm-10"
					wrapperCls="col-9 col-sm-10 col-md-4 col-lg-3 bg-white"
					name="date-range"
					formLabel="date-range"
					onChange={(value) => setDateRange(value)}
					options={infoStatsDateOptions}
					value={dateRange}
				/>
			</div>
			<div className="row">
				{merchantDashboardAnalyticsData.map((data, i) => (
					<PaymentDashboardAnalyticsCard data={data} key={i} />
				))}
			</div>
			<div className="row">
				<div className="col-xl-6 col-md-12">
					<div className="bg-white p-4">
						<div className="title">New Registrations</div>
						<NewRegistrations />
					</div>
					<div className="row">
						<div className="col-xl-6 col-md-12">
							<DashStatistics />
						</div>
						<div className="col-xl-6 col-md-12">
							<div className="bg-white p-4 pt-4 my-5">
								<div className="title">
									Top Merchants Location
								</div>
								{locationsData.map((data) => (
									<TopMerchants
										progress={data?.progress}
										title={data?.title}
										amount={data.amount}
										icon={<Location className="me-1" />}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-6 col-md-12">
					<div className="bg-white p-4">
						<div className="title">Aggregator Statistics</div>
						{new Array(10).fill(10).map((item) => (
							<ActiveMerchant
								title="The Rock Business Enterprice"
								progress={40}
							/>
						))}
					</div>
					<div className="bg-white p-4 my-5">
						<div className="title">Aggregator Statistics</div>
						{new Array(10).fill(10).map((item) => (
							<ActiveMerchant
								title="The Rock Business Enterprice"
								progress={40}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashboardMerchants;
