export const transactionsFiltersDefaultValues = {
  PaymentID: "",
  wallet: false,
  success: false,
  pending: false,
  failed: false,
  dateCreated: null,
};

export const cardTransactionTableHeadings = [
  "Payment ID",
  "email",
  "Channel",
  "Amount",
  "Charges",
  "Status",
  "Transaction Date",
];
