import { useState } from "react";
import Table from "utils/Table/Table";
import ProviderFilters from "../../common/ProviderFilters";
import { ReactComponent as Edit } from "assets/images/icons/edit.svg";
import {
	providerFiltersDefaultValues,
	providerRouteTableHeadings,
	providerRouteTableData,
} from "../../helpers/data";

const ProviderRouteTable = () => {
	const [formValues, setFormValues] = useState({
		...providerFiltersDefaultValues,
	});

	const handleChange = ({ target: { name, value } }) =>
		handleSelect(name, value);

	const handleSelect = (name, value) => {
		console.log({ name, value });
		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	const handleSubmit = () =>
		setFormValues({ ...providerFiltersDefaultValues });

	const getWalletData = () => {
	return providerRouteTableData.map((data) => ({
		...data,
		action: (
			<>
				<Edit className="pointer" />{" "}
				<span className="mx-2">Edit Address</span>
			</>
		),
	}))}

	const handleRowClick = (_, rowValues) => {
		// history.push(`/providers/${rowValues.transactionID}/overview`);
	};
	return (
		<>
			<div className="position-relative">
				<ProviderFilters
					formValues={formValues}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					handleSelect={handleSelect}
				/>
				<Table
					data={getWalletData()}
					headings={providerRouteTableHeadings}
					rowClick={handleRowClick}
				/>
			</div>
		</>
	);
};

export default ProviderRouteTable;
