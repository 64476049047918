import { totalTransactionsChartOptions } from "../utils/data";
import ChartStatus from "components/common/ChartStatus/ChartStatus";
import ChartGraph from "utils/Charts/ChartGraph/ChartGraph";

const data = [
	{
		color: "#F18A87",
		value: 200,
		name: "Successful",
		cls: "recent-success-icon ",
	},
	{
		color: "#FFCB7D",
		value: 250,
		name: "Pending",
		cls: "recent-warning-icon ",
	},
	{
		color: "#2CC7A2",
		value: 350,
		name: "Failed",
		cls: "recent-danger-icon ",
	},
];

const TransactionStatusOther = () => {
	return (
		<div className="bg-white pt-4 my-5">
			<p className="title px-5">Transaction Status</p>
			<div className="flex__center">
				<ChartGraph
					option={totalTransactionsChartOptions(data)}
					styles={{
						height: "255px",
					}}
				/>
			</div>
			<div className="px-2 pb-3">
				{data.map(({ value, name, cls }) => (
					<ChartStatus value={value} name={name} cls={cls} />
				))}
			</div>
		</div>
	);
};

export default TransactionStatusOther;
