import SectionHeader from "../extra/SectionHeader";

export default function SectionContainer({
	children,
	cls,
	iconList,
	id,
	navId,
	navWideMobile,
	headerChildren,
	tabList,
	title,
	tabType,
}) {
	
	return (
		<section className={`section--container section--margin ${cls}`}>
			{(title || tabList) && (
				<SectionHeader
					headerChildren={headerChildren}
					iconList={iconList}
					id={id}
					navId={navId}
					navWideMobile={navWideMobile}
					tabList={tabList}
					title={title}
					tabType={tabType}
				/>
			)}
			{children}
		</section>
	);
}
