import ChartStatus from "components/common/ChartStatus/ChartStatus";
import { totalTransactionsChartOptions } from "../../DashboardPayment/utils/data";
import ChartGraph from "utils/Charts/ChartGraph/ChartGraph";

const data = [
	{
		color: "#509FEF",
		value: 200,
		name: "Active Aggregators",
		cls: "recent-blue-icon ",
	},
	{
		color: "#F1F7FE",
		value: 250,
		name: "Inactive Aggregators",
		cls: "recent-light-blue-icon ",
	},
];

const AggregatorStatistics = () => {
	return (
		<div className=" h-100">
			<div className="flex__center">
				<ChartGraph
					option={totalTransactionsChartOptions(data)}
					styles={{
						height: "282px",
					}}
				/>
			</div>
			<div className="px-2 pb-3">
				{data.map(({ value, name, cls }) => (
					<ChartStatus value={value} name={name} cls={cls} />
				))}
			</div>
		</div>
	);
};

export default AggregatorStatistics;
