import { ReactComponent as GreaterThanIcon } from "assets/images/icons/greater-than.svg";
import IDContainer from "components/common/IDContainer/IDContainer";

const DetailsHeader = ({
	detailID,
	IDName,
	IDIcon,
	title,
	returnAction,
	tabName,
}) => {
	return (
		<>
			<div className="d-flex align-items-center heading-4 pb-4 flex-wrap">
				<span
					className="auth-text-gray pointer mb-4 mb-sm-0"
					onClick={returnAction}
				>
					{tabName}
				</span>
				<GreaterThanIcon className="mx-3 mb-4 mb-sm-0" />
				{title && <span className="fw-bold">{title}</span>}

				{detailID && (
					<IDContainer
						icon={IDIcon}
						IDName={IDName}
						IDValue={detailID}
					/>
				)}
			</div>
		</>
	);
};

export default DetailsHeader;
