import { useState } from "react";
import { toCurrency } from "utils/helpers";
import { PENDING, SUCCESS } from "utils/helpers/constants";
import { walletsTableData } from "components/Dashboard/Watcher/utils/data";
import StatusPill from "utils/pills/StatusPill/StatusPill";
import Table from "utils/Table/Table";
import AllTransactionFilters from "./common/AllTransactionFilters";
import {
	cardTransactionTableHeadings,
	transactionsFiltersDefaultValues,
} from "./helpers/data";

const PaymentTransactions = () => {
	const [formValues, setFormValues] = useState({
		...transactionsFiltersDefaultValues,
	});

	const [isOpen, setIsOpen] = useState(false);
	const [currentRowValues, setCurrentRowValues] = useState({});

	const toggleModal = () => setIsOpen((prev) => !prev);

	const handleChange = ({ target: { name, value } }) =>
		handleSelect(name, value);

	const handleSelect = (name, value) => {
		console.log({ isOpen, currentRowValues });
		console.log({ name, value });
		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	const handleSubmit = () =>
		setFormValues({ ...transactionsFiltersDefaultValues });

	const getWalletData = () =>
		walletsTableData?.map((transaction) => ({
			transactionID: transaction?.id,
			email: transaction?.email,
			channel: transaction?.channel,
			amount: `₦ ${toCurrency(transaction?.amount)}`,
			charges: `₦ ${toCurrency(transaction?.charges)}`,
			status: (
				<StatusPill
					status={transaction?.status}
					success={SUCCESS}
					warning={PENDING}
				/>
			),
			createdAt: transaction?.createdAt,
		}));

	const handleRowClick = (_, rowValues) => {
		// console.log(rowValues);
		setCurrentRowValues(rowValues);
		toggleModal();
	};
	return (
		<>
			<AllTransactionFilters
				formValues={formValues}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				handleSelect={handleSelect}
			/>
			<Table
				data={getWalletData()}
				headings={cardTransactionTableHeadings}
				rowClick={handleRowClick}
			/>
		</>
	);
};

export default PaymentTransactions;
