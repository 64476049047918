import { Modal as BootstrapModal, ModalBody, ModalFooter } from "reactstrap";

import ModalHeader from "./ModalHeader/ModalHeader";

import { ModalLayout } from "./ModalSC";
import {
	CENTER,
	PRIMARY,
	TRANSFER_CENTER,
	SHORT,
	MID_HEIGHT,
} from "components/constants";
// import { colors } from "@components/Layout/extra";

const Modal = ({
	aside,
	asideCls,
	show = true,
	type = PRIMARY,
	height,
	position,
	toggle,
	bodyCls,
	children,
	id,
	footerChildren,
	cls,
	contentCls,
	title,
	subtitle,
	size = "md",
	onEnter,
	onHide,
	centered = true,
}) => {
	const isWithAside = aside ? "d-flex flex-wrap with-aside" : "";
	const isMainSectionWithAside = aside ? "col-12 col-lg-8" : "col-12";
	const isWithFooterChildrenOrAside = footerChildren || aside ? "" : "px-0";
	const isCenter =
		position === CENTER
			? "center-modal "
			: TRANSFER_CENTER
			? "center-modal transfer-modal "
			: "";
	const heightCls =
		height === SHORT
			? "short-modal "
			: height === MID_HEIGHT
			? "mid-height-modal "
			: "";
	return (
		<BootstrapModal
			id={id}
			className={`${isCenter}${heightCls}${cls}`}
			contentClassName={contentCls}
			isOpen={show}
			size={size}
			onEnter={onEnter}
			onHide={onHide}
			centered={centered}
		>
			<ModalLayout colors={{}}>
				{type && (
					<ModalHeader
						type={type}
						toggle={toggle}
						title={title}
						subtitle={subtitle}
					/>
				)}
				<ModalBody
					className={`${isWithAside} ${isWithFooterChildrenOrAside} ${bodyCls}`}
				>
					{aside && (
						<aside
							className={`col-12 col-lg-4 px-5 ps-lg-0 ${asideCls}`}
							id="modal--aside-section"
						>
							{aside}
						</aside>
					)}
					<div
						className={isMainSectionWithAside}
						id="modal--main-section"
					>
						{children}
					</div>
				</ModalBody>
				{footerChildren && <ModalFooter>{footerChildren}</ModalFooter>}
			</ModalLayout>
		</BootstrapModal>
	);
};

export default Modal;
