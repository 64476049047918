import { PENDING, SUCCESS } from "components/constants";
import { useState } from "react";
import {  toCurrency } from "utils/helpers";
import StatusPill from "utils/pills/StatusPill/StatusPill";
import Table from "utils/Table/Table";
import BankingWalletFilters from "../common/BankingWalletFilters";
import BankingTransferModal from "../common/modals/BankingTransferModal";
import {
	bankingTransferTableData,
	bankingTransferTableHeadings,
	bankingWalletsFiltersDefaultValues,
} from "../helpers/data";

const BankingTransfers = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [currentRowValues, setCurrentRowValues] = useState({});
	const [formValues, setFormValues] = useState({
		...bankingWalletsFiltersDefaultValues,
	});

	const toggleModal = () => setIsOpen((prev) => !prev);

	const handleChange = ({ target: { name, value } }) =>
		handleSelect(name, value);

	const handleSelect = (name, value) => {
		console.log({ name, value });
		setFormValues((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	const handleSubmit = () =>
		setFormValues({ ...bankingWalletsFiltersDefaultValues });

	const getWalletData = () =>
		bankingTransferTableData?.map((transaction) => ({
			referenceId: transaction?.referenceId,
			name: transaction?.name,
			account: transaction?.account,
			bank: transaction?.bank,
			amount: `₦ ${toCurrency(transaction?.amount)}`,
			charges: `₦ ${toCurrency(transaction?.charges)}`,
			status: (
				<StatusPill
					status={transaction?.status}
					success={SUCCESS}
					warning={PENDING}
				/>
			),
			createdAt: transaction?.createdAt,
		}));

	const handleRowClick = (_, rowValues) => {
		const transactionData = bankingTransferTableData?.find(
			(transaction) => transaction?.reference === rowValues?.transactionID
		);
		setCurrentRowValues({
			...rowValues,
			...transactionData,
		});
		toggleModal();
	};
	return (
		<>
			<BankingWalletFilters
				formValues={formValues}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				handleSelect={handleSelect}
			/>
			<Table
				data={getWalletData()}
				headings={bankingTransferTableHeadings}
				rowClick={handleRowClick}
			/>
			{isOpen && (
				<BankingTransferModal
					toggle={toggleModal}
					payment={currentRowValues}
					IDTabName={`Transaction Information`}
				/>
			)}
		</>
	);
};

export default BankingTransfers;
