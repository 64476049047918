const IDContainer = ({ icon, IDName, IDValue }) => {
	return (
		<div className="flex__start primary id-container mb-4 mb-sm-0 flex-wrap">
			<span className="">{icon}</span>
			{IDName && (
				<span className="auth-text-gray fw-bold heading-4 pe-3 ">{`${IDName}:`}</span>
			)}
			<span className="black fw-bold text--md">{IDValue.toUpperCase()}</span>
		</div>
	);
};
export default IDContainer;
