import { titleCase } from "utils/helpers";

export default function StatusPill({ cls, status, success, warning }) {
  const smallWidth =
    titleCase(status) === titleCase("success") ||
    status === titleCase("pending") ||
    status === titleCase("failed")
      ? " w-80"
      : "";
  const statusBgColor =
    titleCase(status) === titleCase(success)
      ? " pill--success"
      : titleCase(status) === titleCase(warning)
      ? " pill--warning"
      : " pill--danger";

  return (
    <div className={`text-center${smallWidth}${statusBgColor} ${cls}`}>
      {titleCase(status)}
    </div>
  );
}
