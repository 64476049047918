export const watcherTransactionData = [
  {
    timer: "10 mins ago",
    amount: "N680,000",
    desc: "Card Transaction",
    status: true,
  },
  {
    timer: "13 mins ago",
    amount: "N60,000",
    desc: "Card Transaction",
    status: false,
  },
  {
    timer: "1 mins ago",
    amount: "N680,000",
    desc: "Card Transaction",
    status: true,
  },
  {
    timer: "13 mins ago",
    amount: "N320,000",
    desc: "Card Transaction",
    status: false,
  },
  {
    timer: "13 mins ago",
    amount: "N60,000",
    desc: "Card Transaction",
    status: false,
  },
  {
    timer: "1 mins ago",
    amount: "N680,000",
    desc: "Card Transaction",
    status: true,
  },
  {
    timer: "13 mins ago",
    amount: "N320,000",
    desc: "Card Transaction",
    status: false,
  },
  {
    timer: "1 mins ago",
    amount: "N680,000",
    desc: "Card Transaction",
    status: true,
  },
  {
    timer: "13 mins ago",
    amount: "N320,000",
    desc: "Card Transaction",
    status: false,
  },
  {
    timer: "13 mins ago",
    amount: "N60,000",
    desc: "Card Transaction",
    status: false,
  },
  {
    timer: "1 mins ago",
    amount: "N680,000",
    desc: "Card Transaction",
    status: true,
  },
  {
    timer: "13 mins ago",
    amount: "N320,000",
    desc: "Card Transaction",
    status: false,
  },
];

export const watcherRegistrationData = [
  {
    timer: "10 mins ago",
    name: "Johnson Adeyoju",
    email: "johnsonadeyoju@gmail.com",
    status: true,
  },
  {
    timer: "3 mins ago",
    name: "Johnson Adeyoju",
    email: "johnsonadeyoju@gmail.com",
    status: true,
  },
  {
    timer: "10 mins ago",
    name: "Johnson Adeyoju",
    email: "johnsonadeyoju@gmail.com",
    status: false,
  },
  {
    timer: "13 mins ago",
    name: "Johnson Adeyoju",
    email: "johnsonadeyoju@gmail.com",
    status: true,
  },
  {
    timer: "10 mins ago",
    name: "Johnson Adeyoju",
    email: "johnsonadeyoju@gmail.com",
    status: true,
  },
  {
    timer: "40 mins ago",
    name: "Johnson Adeyoju",
    email: "johnsonadeyoju@gmail.com",
    status: false,
  },
  {
    timer: "10 mins ago",
    name: "Johnson Adeyoju",
    email: "johnsonadeyoju@gmail.com",
    status: false,
  },
  {
    timer: "13 mins ago",
    name: "Johnson Adeyoju",
    email: "johnsonadeyoju@gmail.com",
    status: true,
  },
  {
    timer: "10 mins ago",
    name: "Johnson Adeyoju",
    email: "johnsonadeyoju@gmail.com",
    status: true,
  },
  {
    timer: "40 mins ago",
    name: "Johnson Adeyoju",
    email: "johnsonadeyoju@gmail.com",
    status: false,
  },
];

export const walletsTableData = [
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
  {
    id: "REF-2873-398429",
    email: "dedokunmercy@yahoo.com",
    channel: "Bank Transfer",
    amount: 680400,
    charges: 500,
    status: "Success",
    createdAt: "12 Jan, 2022, 13:54:05 GMT",
  },
];
