import NavTabs from "utils/NavTabs/NavTabs";

export default function SectionHeader({
	iconList,
	id,
	navId,
	navWideMobile,
	headerChildren,
	tabList,
	title,
	rawTitle,
	tabType,
}) {
	return (
		<article className={`header ${tabType ? "side-padding" : "default__header"} flex__between flex-wrap `}>
			<div className={tabList ? "overflow-x" : ""}>
				{title && <div className='heading-1 pb-2 mb-4'>{title}</div>}
				{tabList && (
					<NavTabs
						cls={tabType ? "" : "mb-4"}
						iconList={iconList}
						id={id}
						navId={navId}
						navWideMobile={navWideMobile}
						tabList={tabList}
						rawTitle={rawTitle}
						type={tabType}
					/>
				)}
			</div>
			{headerChildren}
		</article>
	);
}
