import SectionWrapper from "components/common/section/SectionWrapper/SectionWrapper";
import DetailsHeader from "components/common/Details/DetailsHeader/DetailsHeader";
import { ReactComponent as LinkIcon } from "assets/images/icons/link.svg";
import { useHistory, useParams } from "react-router-dom";
import SideTabs from "utils/SideTabs/SideTabs";
import ActivityLog from "./ActivityLog/ActivityLog";
import ProfileInformation from "./ProfileInformation/ProfileInformation";
import LoginActivity from "./LoginActivity/LoginActivity";
import Sessions from "./Sessions/Sessions";
import Permissions from "./Permissions/Permission";
function UserDetails() {
	const history = useHistory();
	const { id } = useParams();
	return (
		<SectionWrapper
			cls="pb-0"
			containerCls="d-flex flex-wrap"
			routeName={
				<DetailsHeader
					detailID={id}
					icon={<LinkIcon className="me-3" />}
					IDName="User Id"
					returnAction={() => history.push("/users/all")}
					tabName="Uuser"
				/>
			}
			tabs={[]}
			navTabChildren={
				<div className="d-flex">
					<button
						type="submit"
						className="btn btn--primary btn--sm ms-2"
					>
						Edit
					</button>
					<button className="btn btn--sm btn--secondary me-4">
						Log User Out
					</button>
				</div>
			}
		>
			<SideTabs
				tabComponent={[
					{
						name: "Profile Information",
						id: "profile_information",
						Comp: <ProfileInformation />,
					},
					{
						name: "Activity Log",
						id: "activity_log",
						Comp: <ActivityLog />,
					},
					{
						name: "Login Activity",
						id: "login_activity",
						Comp: <LoginActivity />,
					},
					{
						name: "Sessions",
						id: "sessions",
						Comp: <Sessions />,
					},
					{
						name: "Permissions",
						id: "permissions",
						Comp: <Permissions />,
					},
				]}
			/>
		</SectionWrapper>
	);
}

export default UserDetails;
