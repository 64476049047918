// import { toLower } from "lodash";
import SectionWrapper from "components/common/section/SectionWrapper/SectionWrapper";
import DetailsHeader from "components/common/Details/DetailsHeader/DetailsHeader";
import { ReactComponent as LinkIcon } from "assets/images/icons/link.svg";
import getTabRoutes from "../../helpers/tabs";
import { useHistory, useParams } from "react-router-dom";
import DetailSection from "./DetailSection";
import { walletResponse } from "../../helpers/data";
import WalletInfoTable from "./WalletInfoTable";
import SideTabs from "utils/SideTabs/SideTabs";

function WalletInfo() {
	const history = useHistory();
	const { id } = useParams();
	return (
		<SectionWrapper
			cls="pb-0"
			containerCls="d-flex flex-wrap"
			routeName={
				<DetailsHeader
					detailID={id}
					icon={<LinkIcon className="me-3" />}
					IDName="Wallet Id"
					returnAction={() => history.push("/banking/wallets")}
					tabName="Banking Wallets"
				/>
			}
			tabs={getTabRoutes(id)}
			navTabChildren={
				<div className="d-flex">
					<button className="btn btn--sm btn--secondary--bordered me-4"></button>
					<button className="btn btn--sm btn--secondary--bordered me-4"></button>
				</div>
			}
		>
			<SideTabs
				tabComponent={[
					{
						name: "NGN-Naira",
						id: "NGN-Naira",
						Comp: (
							<>
								<div className="border-bottom">
									<DetailSection res={walletResponse} />
								</div>
								<WalletInfoTable />
							</>
						),
					},
					{
						name: "GBP-Pounds",
						id: "GBP-Pounds",
						Comp: <h4>Not Available</h4>,
					},
					{
						name: "USD-Dollar",
						id: "USD-Dollar",
						Comp: <h4>Not Available</h4>,
					},
					{
						name: "EUR-Euro",
						id: "EUR-Euro",
						Comp: <h4>Not Available</h4>,
					},
				]}
			/>

		</SectionWrapper>
	);
}

export default WalletInfo;
