import { Progress } from "reactstrap";

const ActiveMerchant = ({ title, progress }) => {
	return (
		<div className="flex__between my-4">
			<p className="col-4 progress-title">{title}</p>
			<div className="row col-8 ">
				<div className="col-11">
					<Progress
						value={progress}
						barClassName="active-merchant-progress"
					/>{" "}
				</div>
				<div className="col-1 ">
					<span progress-title>{progress}%</span>
				</div>
			</div>
		</div>
	);
};

export default ActiveMerchant;
