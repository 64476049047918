import { HEADLESS, NORMAL, PRIMARY, SECONDARY } from "components/constants";

import { ReactComponent as BackIcon } from "assets/images/icons/chevron-down.svg";
import { ReactComponent as CloseCircleIcon } from "assets/images/icons/minimize.svg";
import { ReactComponent as PrintCircleIcon } from "assets/images/icons/print.svg";
import { ReactComponent as OpenCircleIcon } from "assets/images/icons/open.svg";

const ModalHeader = ({
	type,
	toggle,
	prevStep,
	title,
	subtitle,
	closeIconCls,
}) => {
	const render = () => {
		switch (type) {
			case HEADLESS:
				return (
					<div className="flex__end px-5">
						<CloseCircleIcon
							className={`pointer ${closeIconCls}`}
							onClick={toggle}
						/>
					</div>
				);

			case NORMAL:
				return (
					<div className="normal__header">
						<div className="col-11">
							<div className="title mb-2">{title}</div>
							{subtitle && (
								<h3 className="subtitle">{subtitle}</h3>
							)}
						</div>
						<CloseCircleIcon
							className={`pointer ${closeIconCls}`}
							onClick={toggle}
						/>
					</div>
				);

			case PRIMARY:
				return (
					<div className="primary__header px-5 py-4 border-bottom">
						<div className="flex__between">
							<div className="flex__center">
								<h3 className="title mb-0 me-4">{title}</h3>
								<h3 className="subtitle">{subtitle}</h3>
								<PrintCircleIcon
									className="pointer me-4"
									// onClick={toggle}
								/>
								<OpenCircleIcon
									className="pointer"
									// onClick={toggle}
								/>
							</div>
							<div className="">
								<CloseCircleIcon
									className="pointer"
									onClick={toggle}
								/>
							</div>
						</div>
					</div>
				);

			case SECONDARY:
				return (
					<div className="text-center pb-4 position-relative">
						{prevStep && (
							<span
								className="abs position-absolute pointer"
								style={{ left: "10px", top: "15px" }}
							>
								<BackIcon height="25px" onClick={prevStep} />
							</span>
						)}
						<p className="heading6 border-bottom pb-4 pt-0">
							{title}
						</p>
						{toggle && (
							<span
								className="abs position-absolute pointer"
								style={{ right: "0px", top: "0px" }}
							>
								<CloseCircleIcon
									height="25px"
									onClick={toggle}
								/>
							</span>
						)}
					</div>
				);

			default:
				return "";
		}
	};

	return <>{render()}</>;
};

export default ModalHeader;
