import Table from "utils/Table/Table";
import { highestTransferData, highestTransfersHeading } from "./data";

const HighestTransfers = () => {
	return (
		<div>
			<Table
				headings={highestTransfersHeading}
				data={highestTransferData}
			/>
		</div>
	);
};

export default HighestTransfers;
